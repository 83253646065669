<template>
  <v-container>
    <v-row class="overflow-hidden">
      <v-col>
        <div v-if="shipment.refCode" class="ml-2 mt-0 pt-0">
          <b>Folio: </b>{{ shipment.refCode }}
        </div>
        <div v-else>
          Folio:
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-end" cols="2">
        <div>
          <v-autocomplete
              :items="operators"
              v-model="safeUserId"
              :item-text="item => item.firstName + ' ' + item.lastName"
              item-value="userId"
              label="Operador"
              @change="sendUpdateToOperator"
              outlined
              dense
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col class="d-flex justify-end" cols="2">
        <div class=" pt-0 mt-0 mr-2">
          <b>¿Enviar correos a cliente?:</b>

        </div>
        <div class="pr-0 mr-0">
          <v-switch
              v-model="sendToClient"
              :label="sendToClient ? 'Si' : 'No'"
              class="ma-0 pa-0"
              @change="sendUpdateToClient"
          ></v-switch>

        </div>
      </v-col>
    </v-row>
    <v-container class="overflow-auto">
      <div class="d-flex">
        <v-slide-item v-for="(route,index) in routes"
                      :key="index">
          <div class="d-flex"
          >
            <v-container style="width: 350px !important;"
            >
              <!--<editor-fold desc="selects">-->
              <div v-if="route.transportationId===1 && route.orderTypeId===1  ">
                <v-select
                    v-model="route.event"
                    :items="ImpoOcean"
                    append-icon="mdi-ferry"
                    dense
                    item-text="name"
                    item-value="id"
                    label="Evento"
                    menu-props="auto"
                    outlined
                    @change="setLabel(route.event, index)"
                ></v-select>
              </div>
              <div v-if="route.transportationId=== 2 && route.orderTypeId===1">
                <v-select
                    v-model="route.event"
                    :items="ImpoSelect"
                    append-icon="mdi-airplane"
                    dense
                    item-text="name"
                    item-value="id"
                    label="Evento"
                    menu-props="auto"
                    outlined
                    @change="setLabelAereo(route.event, index)"
                ></v-select>
              </div>
              <div v-if="route.transportationId=== 1 && route.orderTypeId===2">
                <v-select
                    v-model="route.event"
                    :items="expoOcean"
                    append-icon="mdi-ferry"
                    dense
                    item-text="name"
                    item-value="id"
                    label="Evento"
                    menu-props="auto"
                    outlined
                    @change="setLabel(route.event, index)"
                ></v-select>
              </div>
              <div v-if="route.transportationId=== 2 && route.orderTypeId===2">
                <v-select
                    v-model="route.event"
                    :items="expoAir"
                    append-icon="mdi-airplane"
                    dense
                    item-text="name"
                    item-value="id"
                    label="Evento"
                    menu-props="auto"
                    outlined
                    @change="setLabelAereo(route.event, index)"
                ></v-select>
              </div>
              <div v-if="route.transportationId===3">
                <v-select
                    v-model="route.event"
                    :items="landSelects"
                    append-icon="mdi-truck"
                    dense
                    item-text="name"
                    item-value="id"
                    label="Evento"
                    menu-props="auto"
                    outlined
                    @change="setLabelTerrestre(route.event, index)"
                ></v-select>
              </div>
              <!--</editor-fold>-->
              <!--<editor-fold desc="autocomplete">-->
              <!--<editor-fold desc="Maritimo">-->
              <!--Estos divs con transportationId son lo que pintan el primero y el ultimo -->
              <div v-if="route.transportationId === 1">
                <div v-if="route.event === 'pickup_confirmation' || route.event === 'delivery'">
                  <s-land-autocomplete
                      v-if="route.isFirst || route.isLast"
                      :id="1"
                      :items="originItems"
                      :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                      :search="route.originAddress"
                      :selections="originSelections"
                      dense
                      label="Origen"
                      transportation="SEA"
                      @change="originSelectedTerrestre"
                      @change2="setIndex(index)"
                      @updateAddress="updateOriginAddress"
                      @updateItems="updateOriginItems"
                  >
                  </s-land-autocomplete>
                </div>
                <div v-else>
                  <div v-if="route.deliveryTypeId === 1">
                    <s-land-autocomplete
                        v-if="route.isFirst || route.isLast"
                        :id="1"
                        :items="originItems"
                        :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                        :search="route.originAddress"
                        :selections="originSelections"
                        dense
                        label="Origen"
                        transportation="SEA"
                        @change="originSelectedTerrestre"
                        @change2="setIndex(index)"
                        @updateAddress="updateOriginAddress"
                        @updateItems="updateOriginItems"
                    >
                    </s-land-autocomplete>
                  </div>
                  <div v-if="route.deliveryTypeId === 2">
                    <google_port
                        v-if="route.isFirst || route.isLast"
                        :id="route.id"
                        :filterRoute="2"
                        :items="route.itemsPort"
                        :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                        :search="route.originAddress"
                        :selections="destination"
                        dense
                        label="Destino"
                        transportation="SEA"
                        @change="destinationSelected"
                        @change2="setIndex(index)"
                        @updateAddress="updateDestinationAddress"
                        @updateItems="updateDestinationItems"
                    >
                    </google_port>
                  </div>
                  <div v-if="route.deliveryTypeId === 3">
                    <google_port
                        v-if="route.isFirst"
                        :id="3"
                        :filterRoute="2"
                        :isOpen="true"
                        :items="originItemsPort"
                        :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                        :search="route.originAddress"
                        :selections="origin"
                        dense
                        label="Origen"
                        transportation="SEA"
                        @change="originSelected"
                        @change2="setIndex(index)"
                        @updateAddress="updateOriginAddress"
                        @updateItems="updateOriginItemsPort"
                    >
                    </google_port>
                    <s-land-autocomplete
                        v-if="route.isLast"
                        :id="1"
                        :items="originItems"
                        :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                        :search="route.originAddress"
                        :selections="originSelections"
                        dense
                        label="Origen"
                        transportation="SEA"
                        @change="originSelectedTerrestre"
                        @change2="setIndex(index)"
                        @updateAddress="updateOriginAddress"
                        @updateItems="updateOriginItems"
                    >
                    </s-land-autocomplete>
                  </div>
                  <div v-if="route.deliveryTypeId === 4">
                    <s-land-autocomplete
                        v-if="route.isFirst"
                        :id="1"
                        :items="originItems"
                        :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                        :search="route.originAddress"
                        :selections="originSelections"
                        dense
                        label="Origen"
                        transportation="SEA"
                        @change="originSelectedTerrestre"
                        @change2="setIndex(index)"
                        @updateAddress="updateOriginAddress"
                        @updateItems="updateOriginItems"
                    >
                    </s-land-autocomplete>
                    <google_port
                        v-if="route.isLast"
                        :id="route.id"
                        :filterRoute="2"
                        :items="route.itemsPort"
                        :placeholder="route.location.port.name"
                        :search="route.originAddress"
                        :selections="destination"
                        dense
                        label="Destino"
                        transportation="SEA"
                        @change="destinationSelected"
                        @change2="setIndex(index)"
                        @updateAddress="updateDestinationAddress"
                        @updateItems="updateDestinationItems"
                    >
                    </google_port>
                  </div>
                </div>
                <!--Estis 2 divs son los que pitan los de en medio -->
                <div v-if="route.event === 'pickup_confirmation' || route.event === 'delivery'">
                  <s-land-autocomplete
                      v-if="!route.isFirst && !route.isLast"
                      :id="1"
                      :items="originItems"
                      :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                      :search="route.originAddress"
                      :selections="originSelections"
                      dense
                      label="Origen"
                      transportation="SEA"
                      @change="originSelectedTerrestre"
                      @change2="setIndex(index)"
                      @updateAddress="updateOriginAddress"
                      @updateItems="updateOriginItems"
                  >
                  </s-land-autocomplete>
                </div>
                <div v-else>
                  <google_port
                      v-if="!route.isFirst && !route.isLast"
                      :id="3"
                      :filterRoute="2"
                      :isOpen="true"
                      :items="originItemsPort"
                      :placeholder="route.location.port === null ? route.location.address : route.location.port.name"
                      :search="route.originAddress"
                      :selections="origin"
                      dense
                      label="Origen"
                      transportation="SEA"
                      @change="originSelected"
                      @change2="setIndex(index)"
                      @updateAddress="updateOriginAddress"
                      @updateItems="updateOriginItemsPort"
                  >
                  </google_port>
                </div>
              </div>
              <!--</editor-fold>-->
              <!--<editor-fold desc="Aereo">-->

              <!--Estos divs con transportationId son lo que pintan el primero y el ultimo -->
              <div v-if="route.transportationId === 2">
                <div v-if="route.event === 'pickup_confirmation' || route.event === 'delivery'">
                  <s-land-autocomplete
                      v-if="route.isFirst || route.isLast"
                      :id="1"
                      :items="originItems"
                      :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                      :search="route.originAddress"
                      :selections="originSelections"
                      dense
                      label="Origen"
                      transportation="SEA"
                      @change="originSelectedTerrestre"
                      @change2="setIndex(index)"
                      @updateAddress="updateOriginAddress"
                      @updateItems="updateOriginItems"
                  >
                  </s-land-autocomplete>
                </div>
                <div v-else>
                  <div v-if="route.deliveryTypeId === 1">
                    <s-land-autocomplete
                        v-if="route.isFirst || route.isLast"
                        :id="1"
                        :items="originItems"
                        :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                        :search="route.originAddress"
                        :selections="originSelections"
                        dense
                        label="Origen"
                        transportation="SEA"
                        @change="originSelectedTerrestre"
                        @change2="setIndex(index)"
                        @updateAddress="updateOriginAddress"
                        @updateItems="updateOriginItems"
                    >
                    </s-land-autocomplete>
                  </div>
                  <div v-if="route.deliveryTypeId === 2">
                    <google_airport
                        v-if="route.isFirst || route.isLast"
                        :id="3"
                        :filterRoute="2"
                        :isOpen="true"
                        :items="route.itemsPort"
                        :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                        :search="route.originAddress"
                        :selections="origin"
                        dense
                        label="Origen"
                        transportation="AIR"
                        @change="originSelectedAereo"
                        @change2="setIndex(index)"
                        @updateAddress="updateOriginAddress"
                        @updateItems="updateOriginItems"
                    >
                    </google_airport>
                  </div>
                  <div v-if="route.deliveryTypeId === 3">
                    <google_airport
                        v-if="route.isFirst"
                        :id="3"
                        :filterRoute="2"
                        :isOpen="true"
                        :items="route.itemsPort"
                        :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                        :search="route.originAddress"
                        :selections="origin"
                        dense
                        label="Origen"
                        transportation="AIR"
                        @change="originSelectedAereo"
                        @change2="setIndex(index)"
                        @updateAddress="updateOriginAddress"
                        @updateItems="updateOriginItems"
                    >
                    </google_airport>
                    <s-land-autocomplete
                        v-if="route.isLast"
                        :id="1"
                        :items="originItems"
                        :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                        :search="route.originAddress"
                        :selections="originSelections"
                        dense
                        label="Origen"
                        transportation="SEA"
                        @change="originSelectedTerrestre"
                        @change2="setIndex(index)"
                        @updateAddress="updateOriginAddress"
                        @updateItems="updateOriginItems"
                    >
                    </s-land-autocomplete>
                  </div>
                  <div v-if="route.deliveryTypeId === 4">
                    <s-land-autocomplete
                        v-if="route.isFirst"
                        :id="1"
                        :items="originItems"
                        :placeholder="route.location.address"
                        :search="route.originAddress"
                        :selections="originSelections"
                        dense
                        label="Origen"
                        transportation="SEA"
                        @change="originSelectedTerrestre"
                        @change2="setIndex(index)"
                        @updateAddress="updateOriginAddress"
                        @updateItems="updateOriginItems"
                    >
                    </s-land-autocomplete>
                    <google_airport
                        v-if="route.isLast"
                        :id="3"
                        :filterRoute="2"
                        :isOpen="true"
                        :items="route.itemsPort"
                        :placeholder="route.location.port.name"
                        :search="route.originAddress"
                        :selections="origin"
                        dense
                        label="Origen"
                        transportation="AIR"
                        @change="originSelectedAereo"
                        @change2="setIndex(index)"
                        @updateAddress="updateOriginAddress"
                        @updateItems="updateOriginItems"
                    >
                    </google_airport>
                  </div>
                </div>
                <div v-if="route.event === 'pickup_confirmation' || route.event === 'delivery'">
                  <s-land-autocomplete
                      v-if="!route.isFirst && !route.isLast"
                      :id="1"
                      :items="originItems"
                      :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                      :search="route.originAddress"
                      :selections="originSelections"
                      dense
                      label="test"
                      transportation="SEA"
                      @change="originSelectedTerrestre"
                      @change2="setIndex(index)"
                      @updateAddress="updateOriginAddress"
                      @updateItems="updateOriginItems"
                  >
                  </s-land-autocomplete>
                </div>
                <div v-else>
                  <google_airport
                      v-if="!route.isLast && !route.isFirst"
                      :id="3"
                      :filterRoute="2"
                      :isOpen="true"
                      :items="route.itemsPort"
                      :placeholder="route.location.port === null ? route.location.address : route.location.port.name"
                      :search="route.originAddress"
                      :selections="origin"
                      dense
                      label="Origen"
                      transportation="AIR"
                      @change="originSelectedAereo"
                      @change2="setIndex(index)"
                      @updateAddress="updateOriginAddress"
                      @updateItems="updateOriginItems"
                  >
                  </google_airport>
                </div>


              </div>
              <!--</editor-fold>-->
              <!--<editor-fold desc="Terrestre">-->
              <div v-if="route.transportationId == 3">
                <s-land-autocomplete
                    :id="1"
                    :items="originItems"
                    :placeholder="route.location.address === null ?  route.location.port.name : route.location.address"
                    :search="route.originAddress"
                    :selections="originSelections"
                    dense
                    label="Origen"
                    transportation="SEA"
                    @change="originSelectedTerrestre"
                    @change2="setIndex(index)"
                    @updateAddress="updateOriginAddress"
                    @updateItems="updateOriginItems"
                >
                </s-land-autocomplete>
              </div>
              <!--</editor-fold>-->

              <!--</editor-fold>-->
              <!--<editor-fold desc="Fechas">-->
              <!-- Esta es laa fecha cuando es primero -->
              <v-row v-if="route.position == 1">
                <v-col cols="12">
                  <v-menu
                      :ref="'menu' + index"
                      v-model="route.menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="route.departureDate"
                          :label="route.labelDate"
                          append-icon="mdi-calendar"
                          dense
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="route.departureDate"
                        color="primary"
                        no-title
                        scrollable
                        @input="route.menu = false"
                    ></v-date-picker>
                  </v-menu>
                  <!--                <v-menu
                                      :close-on-content-click="false"
                                      :return-value.sync="route.departureDate"
                                      min-width="auto"
                                      offset-y
                                      transition="scale-transition"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                          append-icon="mdi-calendar"



                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker



                                    >
                  &lt;!&ndash;                    <v-spacer></v-spacer>
                                      <v-btn
                                          color="primary"
                                          text
                                          @click="route.menu = false"
                                      >
                                        Cancel
                                      </v-btn>
                                      <v-btn
                                          color="primary"
                                          text
                                          @click="$refs['menu' + index][0].save(route.departureDate)"
                                      >
                                        OK
                                      </v-btn>&ndash;&gt;
                                    </v-date-picker>
                                  </v-menu>-->
                </v-col>
              </v-row>
              <!-- Esta es la fecha cuando es ultimo-->
              <v-row v-else>
                <v-col cols="12">
                  <v-menu
                      :ref="'menu' + index"
                      v-model="route.menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="route.arrivalDate"
                          :label="route.labelDate"
                          append-icon="mdi-calendar"
                          dense
                          outlined
                          readonly
                          :change="setLabelLastDayOff(route)"
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="route.arrivalDate"
                        color="primary"
                        no-title
                        scrollable
                        @input="route.menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!--</editor-fold>-->
              <!-- <editor-fold desc="Horas">-->
              <!-- Esta es la hora cuando es primero -->
              <v-row v-if="route.position == 1" no-gutters>
                <vue-timepicker
                    v-model="route.departureTime"
                    :placeholder="route.labelHora" fixed-dropdown-button format="HH:mm"
                    hide-clear-button input-width="100%" manual-input>
                  <template v-slot:dropdownButton>
                    <v-icon class="mr-1" style="margin-bottom: 25px">mdi-clock-time-seven-outline</v-icon>
                  </template>
                </vue-timepicker>
              </v-row>
              <!-- Esta es la hora cuando es ultimo -->
              <v-row v-else no-gutters>
                <vue-timepicker
                    v-model="route.arrivalTime"
                    :placeholder="route.labelHora" fixed-dropdown-button format="HH:mm"
                    hide-clear-button input-width="100%" manual-input>
                  <template v-slot:dropdownButton>
                    <v-icon class="mr-1" style="margin-bottom: 25px">mdi-clock-time-seven-outline</v-icon>
                  </template>
                </vue-timepicker>
              </v-row>
              <!--</editor-fold>-->
              <!-- <editor-fold desc="Botones">-->
              <div v-if="!route.isNew" class="d-flex justify-lg-space-between mb-5">
                <v-btn v-if="route.isLast" :disabled="route.isEventCompleted || isLastEventCompleted(index)"
                       class="blue-grey lighten-1 white--text"
                       @click="llego(index)">Llego
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="(route.isFirst || (!route.isLast) || routes.isNew)"
                       :disabled="route.isEventStarted || isLastEventCompleted(index)" color="primary"
                       @click="salio(index)">Salio
                </v-btn>
              </div>
              <!--</editor-fold>-->
              <!-- <editor-fold desc="Speed dial">-->
              <v-row>
                <v-speed-dial
                    v-model="route.fab"
                    :direction="direction"
                    :transition="transition"
                    @click="hover"
                >
                  <template v-slot:activator>
                    <v-btn
                        v-model="route.fab"
                        :color="route.isEventCompleted ? 'green accent-3' : 'blue darken-4 ml-4'"
                        class="mb-5"
                        dark
                        fab
                    >
                      <v-icon v-if="route.fab">
                        mdi-close
                      </v-icon>
                      <h2 v-else class="font-weight-regular">
                        {{ index + 1 }}
                      </h2>
                    </v-btn>
                  </template>
                  <v-btn
                      v-if="!route.isEventStarted"
                      class="mb-5"
                      color="indigo"
                      dark
                      fab
                      small
                      @click="addLeft(index)"
                  >
                    <v-icon>mdi-ray-end-arrow</v-icon>
                  </v-btn>
                  <v-btn
                      v-if="checkTimeline(index)"
                      class="mb-5"
                      color="red"
                      dark
                      fab
                      small
                      @click="deletePosition(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      class="mb-5"
                      color="indigo"
                      dark
                      fab
                      small
                      @click="addRight(index)"
                  >
                    <v-icon>mdi-ray-start-arrow</v-icon>
                  </v-btn>
                </v-speed-dial>
                <v-progress-linear
                    v-if="!route.isEventCompleted"
                    class="line"
                    indeterminate
                ></v-progress-linear>
                <v-progress-linear v-else class="line" color="green accent-3" value="100"></v-progress-linear>
                <v-btn
                    v-if="index === routes.length - 1"
                    :color="routes[index].isEventCompleted ? 'green accent-3' : 'grey lighten-1'"
                    class="lastButton"
                    elevation="2"
                    fab
                >
                  <v-icon color="white">
                    mdi-check
                  </v-icon>
                </v-btn>
              </v-row>

              <!--</editor-fold>-->
            </v-container>
          </div>
        </v-slide-item>
      </div>

    </v-container>
    <v-row class="justify-center mt-4 overflow-hidden">
      <v-col class="mt-2 d-flex justify-start ml-2" cols="5">
        <div v-if="shipment.client">
          <b>Cliente: </b>{{ shipment.client.name }}
        </div>
        <div v-else>
          Cliente:
        </div>
      </v-col>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="mx-2 ml-16"
              color="primary"
              dark
              elevation="0"
              fab
              small
              v-bind="attrs"
              @click="saveRoute"
              v-on="on"
          >
            <v-icon dark>
              mdi-content-save
            </v-icon>
          </v-btn>
        </template>
        <span>Guardar</span>
      </v-tooltip>
      <v-spacer></v-spacer>

      <v-col cols="2">
        <v-select v-model="shipment.shippingStatusId"
                  :disabled="isVentas || shipment.shippingStatusId === 9"
                  :items="shippingStatus"
                  dense
                  item-text="name"
                  item-value="id"
                  label="Estatus"
                  @change="updateShippingStatus">

        </v-select>
      </v-col>
    </v-row>
    <confetti ref="confeti"/>
  </v-container>

</template>

<script>
import SLandAutocomplete from "@/components/google-maps-autocomplete-vuetify/SLandAutocomplete";
import google_port from "@/components/google-maps-autocomplete-vuetify/index_new";
import google_airport from "@/components/google-maps-autocomplete-vuetify/SAirportAutocomplete";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import confetti from "@/components/confetti";
/*import SenDatePicker from "@/views/Pages/Operation/components/SenDatePicker";
import SAirportAutocomplete from "@/components/google-maps-autocomplete-vuetify/SAirportAutocomplete";*/
import moment from "moment";
import {mapGetters} from "vuex";
// import {mapState} from "vuex";

export default {
  name: "SenRouteTimeline",
  components: {
    SLandAutocomplete, google_port, google_airport, VueTimepicker, confetti // SPortAutocomplete, google_port
    /*SenDatePicker, , , SAirportAutocomplete,*/
  },
  props: {
    value: Object
  },
  computed: {
    operator2: function () {
      return this.shipment.users?.findIndex(x => x.user.roles.some(role => role.roleId === 3))
    },
    safeUserId: {
      get: function() {
        if (this.shipment && this.shipment.users && this.shipment.users[this.operator2]) {
          return this.shipment.users[this.operator2].userId;
        }
        return null; // or a default value
      },
      set: function(newValue) {
        console.log('operator2', newValue, this.operator2)
        if (this.shipment && this.shipment.users && this.shipment.users[this.operator2]) {
          this.$set(this.shipment.users[this.operator2], 'userId', newValue);
        }
      }
    },


    currentShipingStatus: function () {
      return this.shippingStatus.find(status => this.shipment.shippingStatusId === status.id);
    },

    ...mapGetters([
      'timeLine/getRutas'
    ]),
  },
  data: (vm) => ({
    sendToClient: vm.value.sendNotificationsToClient,
    isVentas: false,
    operators: [],
    requiredSelectRule: [(v) => v.length > 0 || "Este campo es obligatorio"],
    shippingStatus: [
      {
        id: 8,
        name: "Embarque en confirmacion"
      },
      {
        id: 1,
        name: "Provisional"
      },
      {
        id: 2,
        name: "En tránsito"
      },
      {
        id: 3,
        name: "Arribados"
      },
      {
        id: 4,
        name: "Detenido"
      },
      {
        id: 5,
        name: "Entregados"
      },
      {
        id: 6,
        name: "Cancelado"
      },
      {
        id: 7,
        name: "Entregado con provisión"
      },
      {
        id: 9,
        name: 'Terminado'
      }
    ],
    ImpoSelect: [
      {name: 'Confirmación de recolección', id: 'pickup_confirmation'},
      {name: 'Confirmación de zarpe', id: 'departure_confirmation'},
      {name: 'Confirmación de arribo', id: 'arrival_confirmation'},
      {name: 'Proceso de despacho', id: 'clearance_process'},
      {name: 'Entrega', id: 'delivery'},
    ],
    ImpoOcean: [
      {name: 'Confirmación de recolección', id: 'pickup_confirmation'},
      {name: 'Confirmación de zarpe', id: 'departure_confirmation'},
      {name: 'Proceso de despacho', id: 'clearance_process'},
      {name: 'Confirmación de arribo', id: 'arrival_confirmation'},
      {name: 'Entrega', id: 'delivery'}
    ],
    fechaOcean: [
      {name: 'recoleccion', id: 'pickup_confirmation'},
      {name: 'ingreso a terminal', id: 'entry_container_terminal'},
      {name: 'despacho de mercancia', id: 'dispatch_merchandise'},
      {name: 'confirmacion de zarpe', id: 'departure_confirmation'},
      {name: 'proceso de despacho', id: 'clearance_process'},
      {name: 'arribo', id: 'arrival_confirmation'},
      {name: 'entrega', id: 'delivery'},
      {name: 'descarga de contenedor', id: 'container_discharge'},
      {name: 'ultimo dia libre', id: 'last_day_off'}
    ],
    expoOcean: [
      {name: 'Confirmación de recolección', id: 'pickup_confirmation'},
      {name: 'Ingreso a terminal', id: 'entry_container_terminal'},
      {name: 'Despacho de mercancía', id: 'dispatch_merchandise'},
      {name: 'Confirmación de zarpe', id: 'departure_confirmation'},
      {name: 'Confirmación de arribo', id: 'arrival_confirmation'},
      {name: 'Entrega', id: 'delivery'},
    ],
    fechaExpoAir: [
      {name: 'recolección'},
      {name: 'salida de vuelo'},
      {name: 'arribo de vuelo'},
      {name: 'despacho'},
      {name: 'entrega'},
    ],
    expoAir: [
      {name: 'Confirmación de recolección', id: 'pickup_confirmation'},
      {name: 'Confirmación de zarpe', id: 'departure_confirmation'},
      {name: 'Confirmación de arribo', id: 'arrival_confirmation'},
      {name: 'Proceso de despacho', id: 'dispatch_merchandise'},
      {name: 'Entrega', id: 'delivery'},
    ],
    fechaLand: [
      {name: 'transito a shipper'},
      {name: 'llegada a shipper'},
      {name: 'carga y tránsito a shipper'},
      {name: 'llegada de recibidor'},
      {name: 'descarga'},
    ],
    landSelects: [
      {name: 'Transito a shipper', id: 'transit_to_shipper'},
      {name: 'LLego con shipper', id: 'arrive_to_shipper'},
      {name: 'Cargando y en transito a recibidor', id: 'loading_transit_to_receiver'},
      {name: 'Llegado a la frontera y esperando documentos', id: 'arrived_at_the_border_and_waiting_for_documents'},
      {name: 'Documentos recibidos y en espera de transferencia', id: 'received_documents_and_waiting_for_transfer'},
      {name: 'Transfer llegó, cruzando la frontera', id: 'transfer_arrived_crossing_the_border'},
      {name: 'La unidad tiene una luz roja en la aduana mexicana', id: 'unit_got_a_red_light_at_Mexican_customs'},
      {
        name: 'El conductor se saltó un semáforo en rojo en la aduana estadounidense',
        id: 'driver_got_a_red_light_at_American_customs'
      },
      {name: 'LLego a recibidor', id: 'arrived_to_receiver'},
      {name: 'Descargando', id: 'Of_loaded'},
    ],
    indexRutas: null,
    model: null,
    menuTest: false,
    routes: [],
    fab: false,
    transition: 'scale',
    hover: true,
    direction: 'right',
    QuotData: {
      Typetransportation: null,
      TypeService: null,
      Inconterm: null,
      typeRoute: null,
      dueDate: new Date().toISOString().substr(0, 10),
      origin: {},
      destination: {},
      searchVariables: {},
      DestinationPort: {},
      OriginPort: {},
      AutocompleteOrigin: false,
      AutocompleteDestination: false,
      DataService: {},
      filterRoute: null
    },
    sameFinal: false,
    sameOrigin: false,
    shipment: vm.value,
    isInit: false,
    rout: null,
    originAddress: "",
    originAddressPort: "",
    UserData: [],
    originSelections: {},
    originSelectionsPort: {},
    destinationSelections: {},
    destinationSelectionsPort: {},
    origin: {},
    destination: {},
    originItems: [],
    originItemsPort: [],
    destinationAddress: "",
    destinationAddressPort: "",
    destinationItems: [],
    destinationItemsPort: [],
    dialog: false,
    icons: [
      'mdi-comment-alert',
    ],
  }),
  mounted() {
    this.getOperators()
    this.sortRoutes()
    this.isVentas = false
    this.$store.getters.roles.forEach((element) => {
      console.log('soy un rol we', element)
      if (element === "Ventas") {
        this.isVentas = true
      }
    })

  },
  methods: {
    emitUpdateEvent(newValue) {
      console.log("passs por aqui")
      // Emitting an event with the new value
      this.$emit('updateSelectedOperator', newValue);
    },
    checkTimeline(index) {
      const twoElements = this.routes.filter(element => element.id > 0);
      console.log('towelements', twoElements)
      if (twoElements.length >= 2 && this.routes[index].id === 0) {
        return true;
      } else if (twoElements.length > 2) {
        return true;
      }
      /*if (this.routes[index].id > 0) {
        return false;
      } else {
        return true;
      }*/
      /*const filteredElements = this.routes.filter(element => element.id > 0);
      if (filteredElements.length > 2 && this.routes[index].id === 0) {
        return false;
      }
      return true;*/
    },
    isLastEventCompleted(index) {
      if (index === 0 && this.routes[index].isEventStarted) {
        return true;
      }
      if (index > 0 && this.routes[index - 1].isEventStarted) {
        return false;
      } else if (index > 0 && !this.routes[index - 1].isEventStarted) {
        return true;
      }
      if (index === this.routes.length - 1 && this.routes[index - 1].isEventStarted) {
        return false;
      }
      if (index === this.routes.length - 1 && !this.routes[index - 1].isEventStarted) {
        return true;
      }
    },
    sendUpdateToClient() {
      this.$emit('updateNotyClient', this.sendToClient)
    },
    sendUpdateToOperator() {
      // If you want to emit the selected operator's ID or any other data
      const selectedOperatorId = this.shipment.users[this.operator2].userId;
      this.$emit('updateNotyClient', selectedOperatorId);

      // If sendToClient is the correct data to emit
      // this.$emit('updateNotyClient', this.sendToClient);
    },
    startConfeti() {
      this.$refs.confeti.start({
        particles: [
          {
            type: 'heart',
          }
        ]
      });
    },
    setLabelTerrestre(event, indexRoute) {
      console.log(event, indexRoute);
      let index = this.landSelects.findIndex(x => x.id === event)
      this.routes[indexRoute].labelDate = 'Fecha de ' + this.fechaLand[index].name
      this.routes[indexRoute].labelHora = 'Hora de ' + this.fechaOcean[index].name
      this.routes[indexRoute].itemsPort = []
    },
    setLabelAereo(event, indexRoute) {
      console.log(event, indexRoute)
      let index = this.expoAir.findIndex(x => x.id === event)
      console.log(index)
      this.routes[indexRoute].labelDate = 'Fecha de ' + this.fechaExpoAir[index].name
      this.routes[indexRoute].labelHora = 'Hora de ' + this.fechaExpoAir[index].name
      this.routes[indexRoute].itemsPort = []
    },
    addEvents(eventArrival){
      let routeContainerDischarge = {
        id: 0,
        labelHora: 'Hora de ' + this.fechaOcean.find(x => x.id === 'container_discharge').name,
        labelDate: 'Fecha de ' + this.fechaOcean.find(x => x.id === 'container_discharge').name,
        transportationId: eventArrival.transportationId,
        shippingId: eventArrival.shippingId,
        supplierId: eventArrival.supplierId,
        arrivalDate: eventArrival.arrivalDate,
        arrivalTime: null,
        departureTime: null,
        departureDate: null,
        event: 'container_discharge',
        isEventCompleted: false,
        position: null,
        location: eventArrival.location,
        hasChanged: false,
        itemsPort: [],
        AutocompleteOrigin: false,
        originAddress: null,
        fab: false,
        menuTime: false,
        menuTime2: false,
        menu2: false,
        menu: false,
        date: "",
        isFirst: false,
        isLast: false,
        deliveryTypeId: eventArrival.deliveryTypeId,
        orderTypeId: eventArrival.orderTypeId
      }
      let index = this.routes.findIndex(x => x.event === 'delivery');

      if (index !== -1) {
        this.routes.splice(index, 0, routeContainerDischarge);
      }else{this.routes.push(routeContainerDischarge)}
      let routeLastDay = {
        id: 0,
        labelHora: 'Hora de ' + this.fechaOcean.find(x => x.id === 'last_day_off').name,
        labelDate: 'Fecha de ' + this.fechaOcean.find(x => x.id === 'last_day_off').name,
        transportationId: eventArrival.transportationId,
        shippingId: eventArrival.shippingId,
        supplierId: eventArrival.supplierId,
        arrivalDate: this.setLabelLastDayOff(eventArrival),
        arrivalTime: null,
        departureTime: null,
        departureDate: null,
        event: 'last_day_off',
        isEventCompleted: false,
        position: null,
        location: eventArrival.location,
        hasChanged: false,
        itemsPort: [],
        AutocompleteOrigin: false,
        originAddress: null,
        fab: false,
        menuTime: false,
        menuTime2: false,
        menu2: false,
        menu: false,
        date: "",
        isFirst: false,
        isLast: false,
        deliveryTypeId: eventArrival.deliveryTypeId,
        orderTypeId: eventArrival.orderTypeId
      }
      this.routes.push(routeLastDay)
      this.sortRoutes2();
    },
    setLabel(event, indexRoute) {
      let eventArrival = this.routes.find(x => x.event === 'arrival_confirmation')
      if((this.shipment.transportationCategoryId === 11 && eventArrival) || this.routes.find(x => x.event === 'container_discharge'))
      {
        this.expoOcean.push(
        {name: 'Descarga de contenedor', id: 'container_discharge'},
        {name: 'Retorno de contenedor', id: 'container_return'},
        {name: 'Ultimo dia libre', id: 'last_day_off'}
      )
      this.ImpoOcean.push(
        {name: 'Descarga de contenedor', id: 'container_discharge'},
        {name: 'Retorno de contenedor', id: 'container_return'},
        {name: 'Ultimo dia libre', id: 'last_day_off'}
      )
      if(event === 'arrival_confirmation' && !this.routes.find(x => x.event === 'container_discharge')) this.addEvents(eventArrival)
    }else{
      this.expoOcean = this.expoOcean.filter(x => x.id !== 'container_discharge' && x.id !== 'last_day_off')
      this.expoOcean = this.ImpoOcean.filter(x => x.id !== 'container_discharge' && x.id !== 'last_day_off')
    }
    if(this.shipment.orderTypeId === 1){
      let nameEvent = this.ImpoOcean.find(x => x.id === event)
      // console.log(index, indexRoute)
      // console.log(this.routes[indexRoute].labelDate, this.fechaExpoOcean[index].name)
      this.routes[indexRoute].labelDate = 'Fecha de ' + this.fechaOcean.find(x => x.id === nameEvent.id).name
      this.routes[indexRoute].labelHora = 'Hora de ' + this.fechaOcean.find(x => x.id === nameEvent.id).name
      this.routes[indexRoute].itemsPort = []
      //this.routes[indexRoute].arrivalDate = 
    }
    if(this.shipment.orderTypeId === 2){
      let nameEvent = this.expoOcean.find(x => x.id === event)
      // console.log(index, indexRoute)
      // console.log(this.routes[indexRoute].labelDate, this.fechaExpoOcean[index].name)
      this.routes[indexRoute].labelDate = 'Fecha de ' + this.fechaOcean.find(x => x.id === nameEvent.id).name
      this.routes[indexRoute].labelHora = 'Hora de ' + this.fechaOcean.find(x => x.id === nameEvent.id).name
      this.routes[indexRoute].itemsPort = []
    }
    },
    setLabelLastDayOff(route){
      let date = new Date(route.arrivalDate ?? new Date())
      if(route.event === 'container_discharge'){
        let lastDayOff = new Date(date.setDate(date.getDate() + (this.shipment.masterBl.freeDays - 1) || 0))
        const lastDayOffEvent = this.routes.findIndex(x => x.event === 'last_day_off')
        if(lastDayOffEvent !== -1){
          this.routes.find(x => x.event === 'last_day_off').arrivalDate = lastDayOff.toISOString().substr(0, 10)
        }
      }
      return date.toISOString().substr(0, 10)
    },
    getOperators() {
      this.$http.get('Employees/operators')
          .then(response => {
            this.operators = response.data.data;
          })
          .catch(error => {
            console.log(error)
          })
    },
    updateShippingStatus() {
      this.shipmentWorking = true;
      this.$http.put(`Shipping/${this.shipment.id}/status/${this.shipment.shippingStatusId}`)
          .then(response => {
            this.message = response.data.message
            console.log(response)
            if (response.data.code === 200) {
              this.$toast.info("Tu embarque fue actualizado!");
            } else {
              this.$toast.error(this.message)
            }
            // this.$refs.statusBar.getCount();
          }).catch(error => {
        console.log(error)
        this.$toast.error(this.message)
      }).finally(() => {
        this.shipmentWorking = false;
        this.updateOrder();
      })
    },
    updateOrder() {
      let item2 = {
        id: this.routes[0].shippingId
      }
      this.$emit('updateOrder', item2)
    },
    updateShippingFromEstatus(estatus) {
      this.$store.dispatch("working", true);
      this.$http.put(`Shipping/${this.shipment.id}/status/${estatus}`).then((response) => {
        console.log(response)
        this.$toast.info('Embarque actualizado.');
      }).catch((error) => {
        console.log(error)
        this.$toast.error("Algo salio mal, intente mas tarde")
      }).finally(() => {
        this.$store.dispatch("working", false);
        this.shipment.shippingStatusId = estatus;
      })
    },

    updateEstatus(estatus) {
      const estatusMap = {
        'pickup_confirmation': 2,
        'departure_confirmation': 2,
        'entry_container_terminal': 2,
        'dispatch_merchandise': 2,
        'arrival_confirmation': 3,
        'delivery': 5
      };

      if (Object.prototype.hasOwnProperty.call(estatusMap, estatus)) {
        this.updateShippingFromEstatus(estatusMap[estatus]);
      }
    },
    llego(index) {
      if (this.isVentas) {
        this.$toast.error("Eee perro tu no puedes hacer esto");
        return;
      }
      if (this.routes[index].event === null) {
        this.$toast.error("Por favor selecciona un evento");
      } else {
        let emails = null;
        if (this.shipment.client.contacts.length > 0) {
          this.shipment.client.contacts.forEach((element) => {
            emails += element.email + ";"
          })
        }
        const date = new Date()
        let info = {
          shippingId: this.shipment.id,
          clientEmail: emails ? emails.slice(0, -1) : 'test@senator-int.com',
          mailMessage: "Hola",
          scheduleAt: date,
        }
        this.$store.dispatch("working", true);
        this.$http.post(`shipping/${this.routes[index].shippingId}/routes/${this.routes[index].position}/completed`, info).then((response) => {
          console.log(response)
          if (response.data.code === 200) {
            this.$toast.info("Se actualizo el evento");
            this.startConfeti();
          } else {
            this.$toast.error("Ocurrio un error");
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error("Ocurrio un error");
        }).finally(() => {
          this.updateEstatus('delivery');
          // Actualiza a estatus 5 si todas las rutas están confirmadas
          this.$store.dispatch("working", false);
          setTimeout(() => {
            this.updateOrder();
          }, 1000)
        })
        console.log(index)
      }
    },
    salio(index) {
      if (this.isVentas) {
        this.$toast.error("Eee perro tu no puedes hacer esto");
        return;
      }
      if (this.routes[index].event === null) {
        this.$toast.error("Por favor selecciona un evento");
      } else {
        this.$store.dispatch("working", true);
        let emails = null;
        if (this.shipment.client.contacts.length > 0) {
          this.shipment.client.contacts.forEach((element) => {
            emails += element.email + ";"
          })
        }
        const date = new Date()
        let info = {
          shippingId: this.shipment.id,
          clientEmail: emails ? emails.slice(0, -1) : 'test@senator-int.com',
          mailMessage: "Hola",
          scheduleAt: date,
        }
        this.$http.post(`shipping/${this.routes[index].shippingId}/routes/${this.routes[index].position}/started`, info).then((response) => {
          console.log(response)
          if (response.data.code === 200) {
            this.$toast.info("Se actualizo el evento");
            this.startConfeti();
          } else {
            this.$toast.error("Ocurrio un error");
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error("Ocurrio un error");
        }).finally(() => {
          this.updateEstatus(this.routes[index].event);
          this.$store.dispatch("working", false);
          setTimeout(() => {
            this.updateOrder();
          }, 1000)
        })
      }
    },
    setIndex(index) {
      console.log(index)
      this.indexRutas = index
    },
    areAllRoutesConfirmed() {
      return this.routes.every(route => route.isEventCompleted);
    },
    checkRoute(rutas) {
      console.log(rutas, 'check')
      let areRoutesValid = true
      rutas.forEach((element) => {
        const isDateValid = element.position === 1 ? element.departureDate != null : element.arrivalDate != null
        areRoutesValid = isDateValid && element.event && element.location.coordinates
      })

      return areRoutesValid
    },
    hoursToISO(hours) {
      let firstPart = new Date();
      firstPart = firstPart.toISOString().substr(0, 11);
      if(hours === null) return null
      return firstPart + hours + ':00'
    },
    convertCoords(arr) {
      return arr.map(element => {
        if (element.coordinates && typeof element.coordinates === 'object' && 'lat' in element.coordinates && 'lng' in element.coordinates) {
          const {lat, lng} = element.coordinates;
          element.coordinates = `${lng},${lat}`;
        }
        return element;
      });
    },
    saveRoute() {
      if (this.isVentas) {
        this.$toast.error("No puedes realizar esta accion");
        return;
      }
      this.$store.dispatch("working", true);
      let rutasEnviar = JSON.parse(JSON.stringify(this.routes))
      console.log(rutasEnviar)
      rutasEnviar.forEach((element, index) => {
        delete rutasEnviar[index].supplier
        delete rutasEnviar[index].itemsPort

        if (element.position != 1 ){
          element.departureDate = null
          element.departureTime = null
          element.arrivalTime = element.arrivalTime ?? this.hoursToISO(element.arrivalTime);
        }else{
          element.arrivalDate = null
          element.arrivalTime = null
          element.departureTime = element.departureTime ?? this.hoursToISO(element.departureTime);
        }

        if (typeof element.location.coordinates === 'object') {

          const {lat, lng} = element.location.coordinates;
          element.location.coordinates = `${lng},${lat}`;
        }
        /*if (element.location.id === 0) {
            rutasEnviar.splice(index, 1)
        }*/
      })
      rutasEnviar = this.convertCoords(rutasEnviar)
      if (this.checkRoute(rutasEnviar)) {
        this.$http.put(`shipping/${this.shipment.id}/routes`, rutasEnviar).then((response) => {
          if (response.data.code === 200) {
            this.$toast.info("Se guardo la timeline");
          } else {
            this.$toast.error("Ocurrio un error");

          }
        }).catch((error) => {
          console.log(error)
        }).finally(() => {
          this.$store.dispatch("working", false);
          setTimeout(() => {
            this.updateOrder();
          }, 1000)
        })
      } else {
        this.$toast.error("Los eventos de la ruta necesitan evento, lugar y fecha para ser guardados");
        this.$store.dispatch("working", false);
      }
    },

    addLeft(index) {
      let ruta = {
        id: 0,
        transportationId: this.value.transportationId,
        shippingId: this.value.id,
        supplierId: this.routes[index].supplierId,
        arrivalDate: null,
        arrivalTime: null,
        departureTime: null,
        departureDate: null,
        event: null,
        labelDate: 'Fecha de',
        labelHora: 'Hora de',
        isEventCompleted: false,
        isEventStarted:false,
        position: null,
        location: {
          id: 0,
          shippingRouteId: 0,
          address: "",
          city: "",
          state: "",
          country: "",
          coordinates: "",
          port: {
            name: ''
          },
        },
        hasChanged: false,
        itemsPort: [],
        AutocompleteOrigin: false,
        originAddress: null,
        fab: false,
        menuTime: false,
        menuTime2: false,
        menu2: false,
        menu: false,
        date: "",
        isFirst: false,
        isLast: false,
        deliveryTypeId: this.value.deliveryTypeId,
        orderTypeId: this.value.orderTypeId
      }
      /*let index = this.routes.findIndex(x => x.id === id)
      this.routes.splice(index, 0, ruta)*/
      this.routes.splice(index, 0, ruta)

      this.sortRoutes2()
    },
    addRight(index) {
      console.log(this.value)
      let ruta = {
        id: 0,
        labelHora: 'Hora de',
        labelDate: 'Fecha de',
        transportationId: this.value.transportationId,
        shippingId: this.value.id,
        supplierId: this.routes[index].supplierId,
        arrivalDate: null,
        arrivalTime: null,
        departureTime: null,
        departureDate: null,
        event: null,
        isEventCompleted: false,
        position: null,
        location: {
          id: 0,
          shippingRouteId: 0,
          address: "",
          city: "",
          state: "",
          country: "",
          coordinates: "",
          port: {
            name: ''
          },
        },
        hasChanged: false,
        itemsPort: [],
        AutocompleteOrigin: false,
        originAddress: null,
        fab: false,
        menuTime: false,
        menuTime2: false,
        menu2: false,
        menu: false,
        date: "",
        isFirst: false,
        isLast: false,
        deliveryTypeId: this.value.deliveryTypeId,
        orderTypeId: this.value.orderTypeId
      }
      this.routes.splice(index + 1, 0, ruta)
      this.sortRoutes2();
    },
    deletePosition(index) {
      if (this.routes.length === 2) {
        this.$toast.error("Tienen que existir minimo 2 rutas!");
        return
      }
      if (this.routes[index].id === 0) {
        this.routes.splice(index, 1);
        return;
      }
      this.$store.dispatch("working", true);
      this.$http.delete(`shipping/${this.routes[index].shippingId}/routes/${this.routes[index].position}`)
          .then(response => {
            if (response.data.code === 200) {
              this.$toast.info("Se actualizo el evento");
              this.routes.splice(index, 1);
              this.sortRoutes2();
            } else {
              this.$toast.error("Ocurrio un error");
              this.$nextTick(() => {
              })
            }
          })
      this.$store.dispatch("working", false);
    },
    sortRoutes2() {
      let lenght = this.routes.length
      this.routes.forEach((element, index) => {
        if (index === 0) {
          this.routes[index].isFirst = true
        } else {
          this.routes[index].isFirst = false
        }
        if (index === lenght - 1) {
          this.routes[index].isLast = true
        } else {
          this.routes[index].isLast = false
        }
        this.routes[index].position = index + 1
      })
      // this.$store.dispatch('timeLine/addRutasAction', this.routes, {root: true})
    },
    sortRoutes() {
      console.log(this.value.routes.length)
      if (this.value.routes.length === 0) {
        this.routes = []
      } else {
        this.routes = JSON.parse(JSON.stringify(this.value.routes))
        console.log(this.routes)
        let delivery = JSON.parse(JSON.stringify(this.value.deliveryTypeId))
        let orderid = JSON.parse(JSON.stringify(this.value.orderTypeId))
        let transId = JSON.parse(JSON.stringify(this.value.transportationId))
        console.log('deliveryID: ', delivery, 'OrderID: ', orderid)
        this.routes.sort((a, b) => {
          return a.position - b.position
        })
        let length = this.routes.length
        this.routes = this.routes.map((element, index) => {
          element.departureDate = (this.formatDates(2, element.departureDate))
          element.arrivalDate = (this.formatDates(2, element.arrivalDate))
          element.departureTime = (this.formatDates(1, element.departureTime)) ?? ''
          element.arrivalTime = (this.formatDates(1, element.arrivalTime)) ?? ''
          let supplierId = null;
          if (this.value.transportationId === 2) {
            supplierId = this.value.masterBl?.airline?.supplierId;
          } else {
            supplierId = this.value.masterBl?.shippingLine?.supplierId;
          }
          return {
            ...element,
            supplierId: supplierId,
            hasChanged: false,
            itemsPort: [],
            AutocompleteOrigin: false,
            originAddress: "",
            destinationAddress: "",
            fab: false,
            menuTime: false,
            menuTime2: false,
            deliveryTypeId: delivery,
            menu2: false,
            menu: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            isFirst: index === 0,
            isLast: index === length - 1,
            transportationId: transId,
            orderTypeId: orderid,
            labelDate: 'Fecha de',
            labelHora: 'Hora de',
            isNew: !element.event,
          }
        })
        console.log(this.routes, 'sortroutes')
        this.routes.forEach((element, index) => {
          console.log(element.location.port, 'port')
          if (!element.location.port) {
            this.routes[index].location.port = {
              city: '',
              coordinates: '',
              country: '',
              name: '',
            }
          }
        })
        //this.$store.dispatch("timeLine")
      }
      this.routes.forEach((element, index) => {
        if (this.value.transportationId === 1) {
          this.setLabel(element.event, index)
        }
        if (this.value.transportationId === 2) {
          this.setLabelAereo(element.event, index)
        }
        if (this.value.transportationId === 3) {
          this.setLabelTerrestre(element.event, index)
        }
      })
    },
    formatDates(type, data) {
      if (type === 1 && data != null) {
        console.log(data)
        const timeFormat = new Intl.DateTimeFormat('default', {
          hour12: false,
          hour: "numeric",
          minute: "numeric",
        });
        const date = new Date(data)
        console.log(timeFormat)
        data = timeFormat.format(date)
        console.log(data)
      }
      if (type === 2) {
        if (data === null) {
          data = null
        } else {
          data = data.substr(0, 10)
        }
      }
      return data
    },
    updateOriginAddress(text) {
      this.originAddress = text;
      this.routes[this.indexRutas].originAddress = text;
    },
    updateOriginAddressPort(text) {
      this.routes[this.indexRutas].originAddress = text
    },
    updateOriginItems(items) {
      this.originItems = items;
      this.routes[this.indexRutas].itemsPort = items
    },
    updateOriginItemsPort(items) {
      console.log(items)
      this.originItemsPort = items;
      this.routes.forEach((element, index) => {
        console.log(element)
        this.routes[index].itemsPort = items
      })

    },
    updateDestinationAddress(text) {
      this.destinationAddress = text
      this.routes[this.indexRutas].originAddress = text;
    },
    updateDestinationAddressPort(text) {
      this.destinationAddressPort = text
    },
    updateDestinationItems(items) {
      console.log('update.destinatinoaddres')
      this.destinationItems = items;
      this.routes[this.indexRutas].itemsPort = items;
    },
    updateDestinationItemsPort(items) {
      this.destinationItemsPort = items;
    },
    moment: function (date) {
      return moment(date)
    },
    originSelected(selections) {
      try {
        console.log("Selection result", selections)
        this.origin = selections;
        this.routes[this.indexRutas].location.address = selections.description;
        this.$nextTick(() => {
          try {
            if (this.origin.details) {
              this.routes[this.indexRutas].location.coordinates = this.origin.details.location.lat + ',' + this.origin.details.location.lng
              this.routes[this.indexRutas].location.country = this.origin.details.address_components.filter((value) => value.types.findIndex((a) => a === "country") !== -1)[0].short_name;
              this.routes[this.indexRutas].location.state = this.origin.details.address_components.filter((value) => value.types.findIndex((a) => a === "administrative_area_level_1") !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex((a) => a === "country") !== -1)[0].long_name;
              this.routes[this.indexRutas].location.city = this.origin.details.address_components.filter((value) => value.types.findIndex((a) => a === "locality" || a === "sublocality" || a === "administrative_area_level_3" || a === "neighborhood" || a === "administrative_area_level_2") !== -1)[0]?.long_name;
              this.routes[this.indexRutas].location.coordinates = `${this.origin.details.location.lat},${this.origin.details.location.lng}`;
              this.routes[this.indexRutas].location.address = this.origin.description;


              let index = this.origin.ports.findIndex((e) => e.selected);


              this.origin.address = this.originSelections.description;

              if (index !== -1) {
                this.routes[this.indexRutas].location.port.city = this.origin.ports[index].city;
                this.routes[this.indexRutas].location.port.coordinates = `${this.origin.ports[index].coordinates[0]},${this.origin.ports[index].coordinates[1]}`;
                this.routes[this.indexRutas].location.port.country = this.origin.ports[index].country;
                this.routes[this.indexRutas].location.port.name = this.origin.ports[index].name;

                if (this.origin.type === "port") {
                  this.originAddress = `${this.origin.ports[index].name}, ${this.origin.ports[index].city}, ${this.origin.ports[index].country}`;
                }


              }
            }
          } catch (e) {
            this.orderCreation.origin = {};
            throw new Error(
                `There was an error while selecting the item ${JSON.stringify(
                    selections
                )} \n` + e
            );
          }
        });
      } catch (error) {
        this.QuotData.origin = {};
        console.log(error);
        throw new Error(
            `There was an error while selecting a new origin ${JSON.stringify(
                selections
            )} \n` + error
        );
      }
      console.log(this.orderCreation)
    },
    originSelectedPort(selections) {
      this.origin = selections
      this.$store.state.timeLine.rutas[this.indexRutas].originAddress = selections.description

      try {
        this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name


        this.$nextTick(() => {
          try {
            if (this.origin.details) {

              this.QuotData.origin["country"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.QuotData.origin["state"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              this.QuotData.origin["city"] = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                  a === "sublocality" ||
                  a === "administrative_area_level_3" ||
                  a === 'neighborhood' ||
                  a === "administrative_area_level_2") !== -1)[0]?.long_name
              this.QuotData.origin["coordinates"] = this.origin.details.location;
              this.QuotData.origin["ports"] = this.origin.ports;
              this.QuotData.origin["address"] = this.origin.description;
              this.QuotData.AutocompleteOrigin = true;
              let index = this.origin.ports.findIndex(e => e.selected);
              this.QuotData.origin["ports"] = this.origin.ports;
              console.log(index)

              this.origin.address = this.originSelectionsPort.description;
              if (index !== -1) {

                this.QuotData.OriginPort = this.origin.ports[index];
                this.QuotData.origin["ports"].name = this.origin.ports[index].name;
                this.QuotData.origin["ports"].city = this.origin.ports[index].city;
                this.origin.ports[index].coordinates[0] = parseInt(this.origin.ports[index].coordinates[0]);
                this.origin.ports[index].coordinates[1] = parseInt(this.origin.ports[index].coordinates[1]);
                this.QuotData.origin["ports"].coordinates = `${this.origin.ports[index].coordinates[0]},${this.origin.ports[index].coordinates[1]}`;
                this.QuotData.origin["ports"].country = this.origin.ports[index].country;

                if (this.origin.type === 'port') {
                  this.originAddressPort = `${this.QuotData.origin["ports"].name}, ${this.QuotData.origin["ports"].city}, ${this.QuotData.origin["ports"].country}`
                }


              }
            } else {
              this.QuotData.origin = {}
              this.originAddressPort = "";
            }
          } catch (e) {
            this.QuotData.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        this.QuotData.origin = {}
        console.log(error)
        throw new Error(`There was an error while selecting a new origin ${JSON.stringify(selections)} \n` + error)
      }
    },
    originSelectedAereo(selections) {
      try {
        this.origin = selections;
        this.routes[this.indexRutas].location.address = selections.description;
        this.$nextTick(() => {
          try {
            if (this.origin) {
              console.log(selections)
              this.routes[this.indexRutas].location.coordinates = this.origin.details.location.lat + ',' + this.origin.details.location.lng
              this.routes[this.indexRutas].location.coordinates = `${this.origin.details.location.lat},${this.origin.details.location.lng}`;
              this.routes[this.indexRutas].location.address = this.origin.description;
              this.routes[this.indexRutas].location.country = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
              this.routes[this.indexRutas].location.city = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
              this.routes[this.indexRutas].location.state = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
              let index = this.origin.airports.findIndex(e => e.selected);
              if (index !== -1) {
                console.log(this.origin.airports[index].name)

                this.routes[this.indexRutas].location.port.name = this.origin.airports[index].name;
                this.routes[this.indexRutas].location.port.city = this.origin.airports[index].city;
                this.routes[this.indexRutas].location.port.coordinates = `${this.origin.airports[index].lat},${this.origin.airports[index].lon}`;
                this.routes[this.indexRutas].location.port.country = this.origin.airports[index].country;

                if (this.origin.type === 'airport') {
                  this.originAddress = `${this.origin.airports[index].name}, ${this.origin.airports[index].city}, ${this.origin.airports[index].country}`
                }

              }
            } else {
              this.orderCreation.origin = {}
            }
          } catch (e) {
            this.orderCreation.origin = {}
            throw new Error(`There was an error while selecting the item ${JSON.stringify(selections)} \n` + e)
          }
        })
      } catch (error) {
        this.orderCreation.origin = {}
        console.log(error)
      }
    },
    destinationSelected: function (selections) {
      console.log('entro aqui')
      this.destination = selections
      this.destinationAddress = selections.description
      console.log(selections)
      this.routes[this.indexRutas].location.address = selections.description
      this.$nextTick(() => {
        try {
          if (!Array.isArray(this.destination)) {
            this.routes[this.indexRutas].location["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
            this.routes[this.indexRutas].location["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
            this.routes[this.indexRutas].location["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                a === "sublocality" ||
                a === "administrative_area_level_3" ||
                a === 'neighborhood' ||
                a === "administrative_area_level_2") !== -1)[0]?.long_name
            this.routes[this.indexRutas].location["coordinates"] = this.destination.details.location
            console.log(this.destination.ports)
            this.routes[this.indexRutas].location.port.city = this.destination.ports[0].city
            this.routes[this.indexRutas].location.port.coordinates = this.destination.ports[0].coordinates[0].toString() + ',' + this.destination.ports[0].coordinates[1].toString()
            this.routes[this.indexRutas].location.port.country = this.destination.ports[0].country
            this.routes[this.indexRutas].location.port.name = this.destination.ports[0].name
            /*
            this.routes[this.indexRutas].location.port.name*/

            this.QuotData.AutocompleteDestination = true;
            this.QuotData.destination["address"] = this.destination.description;

            let index = this.destination.ports.findIndex(e => e.selected);
            this.QuotData.destination["ports"] = this.destination.ports;

            // this.Origin.address = this.originSelections.description;
            if (index !== -1) {
              this.QuotData.DestinationPort = this.destination.ports[index];
              this.routes[this.indexRutas].location["port"].name = this.destination.ports[index].name;
              this.routes[this.indexRutas].location["port"].city = this.destination.ports[index].city;
              this.routes[this.indexRutas].location[index].coordinates[0] = parseInt(this.destination.ports[index].coordinates[0]);
              this.routes[this.indexRutas].location[index].coordinates[1] = parseInt(this.destination.ports[index].coordinates[1]);
              this.routes[this.indexRutas].location["port"].coordinates = `${this.destination.ports[index].coordinates[0]},${this.destination.ports[index].coordinates[1]}`;
              this.routes[this.indexRutas].location["port"].country = this.destination.ports[index].country;

              if (this.destination.type === 'port') {
                this.destinationAddress = `${this.QuotData.destination["ports"].name}, ${this.QuotData.destination["ports"].city}, ${this.QuotData.destination["ports"].country}`
              }

            }

            // this.setPath()
          }
        } catch (e) {
          console.log(e);
          this.QuotData.destination = {}
        }
      })
    },
    destinationSelectedPort: function (selections) {
      this.destination = selections
      this.destinationAddressPort = selections.description
      this.$nextTick(() => {
        try {
          if (!Array.isArray(this.destination)) {
            this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
            this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
            this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
                a === "sublocality" ||
                a === "administrative_area_level_3" ||
                a === 'neighborhood' ||
                a === "administrative_area_level_2") !== -1)[0]?.long_name
            this.QuotData.destination["coordinates"] = this.destination.details.location
            this.QuotData.destination["ports"] = this.destination.ports;
            this.QuotData.AutocompleteDestination = true;
            this.QuotData.destination["address"] = this.destination.description;

            let index = this.destination.ports.findIndex(e => e.selected);
            this.QuotData.destination["ports"] = this.destination.ports;

            // this.Origin.address = this.originSelections.description;
            if (index !== -1) {
              this.QuotData.DestinationPort = this.destination.ports[index];
              this.QuotData.destination["ports"].name = this.destination.ports[index].name;
              this.QuotData.destination["ports"].city = this.destination.ports[index].city;
              this.destination.ports[index].coordinates[0] = parseInt(this.destination.ports[index].coordinates[0]);
              this.destination.ports[index].coordinates[1] = parseInt(this.destination.ports[index].coordinates[1]);
              this.QuotData.destination["ports"].coordinates = `${this.destination.ports[index].coordinates[0]},${this.destination.ports[index].coordinates[1]}`;
              this.QuotData.destination["ports"].country = this.destination.ports[index].country;

              if (this.destination.type === 'port') {
                this.destinationAddressPort = `${this.QuotData.destination["ports"].name}, ${this.QuotData.destination["ports"].city}, ${this.QuotData.destination["ports"].country}`
              }

            }

            // this.setPath()
          }
        } catch (e) {
          console.log(e);
          this.QuotData.destination = {}
        }
      })
    },
    originSelectedTerrestre(selections) {
      console.log('llegue')
      this.origin = selections;
      this.routes[this.indexRutas].originAddress = selections.description

      this.$nextTick(() => {
        try {
          console.log(this.origin)
          this.routes[this.indexRutas].location.coordinates = this.origin.details.location.lat + ',' + this.origin.details.location.lng
          this.routes[this.indexRutas].location.address = this.origin.description
          this.routes[this.indexRutas].location.country = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.routes[this.indexRutas].location.state = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.routes[this.indexRutas].location.city = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
          this.routes[this.indexRutas].location.state = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.routes[this.indexRutas].location.city = this.origin.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.routes[this.indexRutas].location.coordinates = `${this.origin.details.location.lat},${this.origin.details.location.lng}`;
          this.routes[this.indexRutas].AutocompleteOrigin = true;
        } catch (e) {
          console.log(e)
        }
      })
    },
    destinationSelectedTerrestre(selections) {
      this.destination = selections;
      this.destinationAddress = selections.description;
      this.destination.contry = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
      this.destination.state = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
      this.destination.city = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name

      this.$nextTick(() => {
        try {
          this.QuotData.destination["address"] = this.destination.description
          this.QuotData.destination["country"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].short_name
          this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' || a === "sublocality" || a === "administrative_area_level_3" || a === 'neighborhood') !== -1)[0]?.long_name
          this.QuotData.destination["state"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'administrative_area_level_1') !== -1)[0]?.long_name ?? this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'country') !== -1)[0].long_name
          this.QuotData.destination["city"] = this.destination.details.address_components.filter((value) => value.types.findIndex(a => a === 'locality' ||
              a === "sublocality" ||
              a === "administrative_area_level_3" ||
              a === 'neighborhood' ||
              a === "administrative_area_level_2") !== -1)[0]?.long_name
          this.QuotData.destination["coordinates"] = this.destination.details.location;
          this.QuotData.AutocompleteDestination = true;
        } catch (e) {
          this.QuotData.destination = {}
        }
      })
    },
    defaultPlaces() {
      this.originItems = [];
      this.originItemsPort = [];
      this.destinationItems = [];
      this.destinationItemsPort = [];
      this.origin = {};
      this.destination = {};
      this.originAddress = "";
      this.destinationAddress = "";
      this.destinationAddressPort = "";
    },
    Butttonn() {
      console.log(this.QuotData.destination["address"])
    },
  },
  watch: {
    shipment: {
      deep: true,
      // immediate: false,
      handler(value) {
        if (!this.isInit) {
          this.isInit = true;
          return;
        }
        this.sortRoutes()
        this.$emit("change", value);
        this.$emit("input", value)
      }
    },
    value: {
      deep: true,
      handler(value) {
        this.shipment = value
        this.sortRoutes()
      }
    }
  },
}
</script>

<style scoped>
>>> .vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul li:not([disabled]).active:hover, .vue__time-picker .dropdown ul li:not([disabled]).active:focus, .vue__time-picker-dropdown ul li:not([disabled]).active, .vue__time-picker-dropdown ul li:not([disabled]).active:hover, .vue__time-picker-dropdown ul li:not([disabled]).active:focus {
  background: #0d4d98;
  color: #fff;
}

>>> .dropdown ul li:not([disabled]).active {
  background-color: #0d4d98;
}

>>> .vue__time-picker input.display-time {
  border: 2px solid #9e9e9e !important;
  border-radius: 5px;
  height: 40px;
  margin-bottom: 26px;
  font-size: 16px;
}

.lastButton {
  position: relative;
  bottom: 79px;
  left: 97%;
}

.line {
  position: relative;
  width: 99%;
  height: 4px !important;
  bottom: 48px;
  left: 20px;
}

/*.line::after {
  content: ">";
  position: relative;
  bottom: 10px;
  left: 90%;
  color: #ff0000;
}*/

.timeLine {
  position: relative;
  bottom: 48px;
  padding-right: 500px;
}

.supplier-autocomplete {
  margin: auto;
  max-width: 400px;
}

.supplier-autocomplete >>> .v-input__prepend-outer {
  margin: auto 0;
}

.supplier-autocomplete >>> .v-input__append-outer {
  margin: auto 0;
}

.supplier-autocomplete >>> .v-input__control {
  margin: auto 0;
}

.supplier-autocomplete >>> .v-input__control .v-input__slot {
  padding: 0 1em;
}

/*.v-divider-full {
  position: absolute;
  width: 90%;
  top: 2.9rem;
}

.position-relative {
  position: relative;
}

>>> .v-input__control.v-text-field__details {
  display: none;
}



.fixed-col-size {
  min-width: 250px;
}

.pricesHeader {
  min-height: 70px;
}

.table-fee-row > td {
  min-width: 100px;
  max-width: 250px;
  padding: 0 !important;
}

.table-fee-row > td.table-actions-row {
  max-width: 30px;
  min-width: 30px;
}

.table-fee-input >>> .v-input__control .v-input__slot {
  padding: 0;
}

.table-fee-input {
  padding: 0;
  margin: 0
}

.text-red >>> .v-text-field__slot input {
  color: red
}

.text-black >>> .v-text-field__slot input {
  color: black
}


.fee-dropzone {
  min-height: 100px;
  padding: 0
}

.more-options-text >>> .v-input__control .v-input__slot {
  padding: 0
}

.v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
}

.v-application .d-flex {
  display: block !important;
}

>>> .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px;
}

>>> .v-messages {
  !* flex: 1 1 auto; *!
  !* font-size: 12px; *!
  min-height: 0px;
  min-width: 1px;
  position: relative;
}*/
>>> .v-slide-group__next, .v-slide-group__prev {
  align-items: center;
  display: flex;
  flex: 0 1 52px;
  justify-content: center;
  min-width: 0px;
}

>>> .v-slide-group__content {
  display: flex;
  flex: 1 0 auto;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  justify-content: center;
}


>>> .v-slide-group__content {
  overflow-x: scroll;
}

>>> .v-slide-group__prev {
  display: none;
}

>>> .v-slide-group__next {
  display: none;
}

</style>
