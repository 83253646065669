var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('order-search-bar',{staticStyle:{"overflow-y":"auto"},attrs:{"filters":false,"status":8,"url":'Shipping'},on:{"item-selected":_vm.selectOrder}}),_c('div',[_c('v-container',{attrs:{"fluid":"","no-gutters":""}},[_c('v-row',{staticStyle:{"margin-left":"21rem"}},[_c('sen-status-bar',{ref:"statusBar",staticClass:"mb-2"})],1),(_vm.shipment.shippingStatusId === 9)?_c('div',{style:({
        height: '100vh',
        marginLeft: '21rem',
        textAlign: 'center',
      })},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 ml-16",attrs:{"elevation":"0","color":"primary","fab":"","small":"","disabled":_vm.shipment.shippingStatusId !== 9},on:{"click":_vm.openShipment}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-lock-open-variant ")])],1)]}}],null,false,494933719)},[_c('span',[_vm._v("Reabrir Embarque")])])],1):_c('div',{key:_vm.shipment.id},[_c('v-row',{staticStyle:{"margin-left":"21rem"}},[_c('v-col',{staticStyle:{"padding-left":"0px"},attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","min-width":"99%","outlined":"","tile":""}},[_c('v-card-text',[_c('v-row',{staticClass:"d-flex justify-center max-height:500px"},[_c('v-col',{staticClass:"overflow-x-auto pl-0"},[_c('SenRouteTimeline',{on:{"updateNotyClient":_vm.updateNotyClient,"updateOrder":_vm.selectOrder},model:{value:(_vm.shipment),callback:function ($$v) {_vm.shipment=$$v},expression:"shipment"}})],1)],1)],1)],1)],1)],1)],1),_c('v-row',{staticStyle:{"margin-left":"21rem"}},[_c('test2',{attrs:{"shipment":_vm.shipment,"updateNotyClient":_vm.notiClient},on:{"updateCarga":_vm.selectOrder}})],1)],1)],1),_c('chatSEN',{ref:"chat",attrs:{"ref-code":_vm.shipment.refCode}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }