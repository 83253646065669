<template>
  <v-card class="elevation-0 mt-5" outlined tile min-width="99%">
    <v-tabs>
      <v-tab href="#master" :disabled="isVentas" v-if="!isVentas">
        Master
      </v-tab>
      <v-tab href="#compra">
        Compra
      </v-tab>
      <v-tab href="#venta">
        Venta
      </v-tab>
      <v-tab href="#ruta">
        Historial de ruta
      </v-tab>
      <v-spacer></v-spacer>
      <tabButtons :shipment="shipment"/>
      <v-tab-item value="master">
        <masterBL :shipment="shipment" @updateCargo="updateCargo" :es-vendedor="isVentas" :updateNotyClient="updateNotyClient"/>
      </v-tab-item>
      <v-tab-item value="compra">
        <compraBL :shipment="shipment" :es-vendedor="isVentas" @updateCargo="updateCargo"/>
      </v-tab-item>
      <v-tab-item value="venta">
        <ventaBL :shipment="shipment" @updateCargo="updateCargo" :es-vendedor="isVentas"/>
      </v-tab-item>
      <v-tab-item value="ruta">
        <routeInfo :shipment="shipment"/>
      </v-tab-item>
    </v-tabs>
  </v-card>


</template>

<script>
import masterBL from './components/masterBL'
import compraBL from './components/compraBL'
import ventaBL from './components/ventaBL'
import tabButtons from "./components/tabButtons";
import routeInfo from './components/routeInfo';
import {mapGetters} from "vuex"

export default {
  name: "test2",

  props: {
    updateNotyClient: null,
    shipment: {},
    step: {},
  },
  components: {
    masterBL,
    compraBL,
    ventaBL,
    tabButtons,
    routeInfo,
  },
  mounted() {
    this.isVentas = false
    this.$store.getters.roles.forEach((element) => {
      console.log('soy un rol we', element)
      if (element === "Ventas") {
        this.isVentas = true
      }
    })
  },
  computed: {
    ...mapGetters([
      'working'
    ])
  },
  data() {
    return {
      isVentas: false,
      componentKey: 0,
      switch1: true,
      confirmedService: 1,
      prices: [],
      tab: null,
      items: [
        'General', 'Master', 'Compra', 'Venta', 'Information/Doc',
      ],
      items2: [
        {tab: 'One', content: 'Tab 1 Content'},
        {tab: 'Two', content: 'Tab 2 Content'},
        {tab: 'Three', content: 'Tab 3 Content'},
        {tab: 'Four', content: 'Tab 4 Content'},
        {tab: 'Five', content: 'Tab 5 Content'},
      ],
      // stepper stuff
      e1: 1,
      steps: 2,
      e6: 1,
    }
  },
  watch: {
    'shipment.id': {
      handler() {
        console.log('entre')
        this.componentKey += 1
      }
    },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    }
  },
  methods: {
    updateCargo(event) {
      this.$emit('updateCarga', event)
    },
    deleteRowMasterCarga(event) {
      console.log(event)
      this.$emit('deleteRowMasterCarga', event)
    },
    addRowMasterCarga(event) {
      console.log('aqui emito test2, ', event)
      this.$emit('addRowMasterCarga', event)
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
    }
  }
}
</script>
<style>

.Header2 {
  color: grey;
}

.col-8 {
  padding: 0;
}

.col-4 {
  padding: 0;
}
</style>
