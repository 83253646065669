<template>
  <v-row class="overflow-x-auto flex flex-nowrap justify-center" style="max-height: 88px;">
    <v-col style="min-width: 150px" cols="1">
      <v-card outlined flat  @click="updateStatus(1)" :disabled="this.$store.state.status.data.status === 1">
        <v-card-title class="justify-center align-content-center pa-1 pb-0">
          <h5 class="text-center indigo--text">Provisional</h5>
        </v-card-title>
        <v-card-text class="justify-center align-content-center pa-1 pt-0">
          <h2 class="text-center">
            {{provisional}}
          </h2>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col style="min-width: 150px" cols="1">
      <v-card outlined flat  @click="updateStatus(2)" :disabled="this.$store.state.status.data.status === 2">
        <v-card-title class="justify-center align-content-center pa-1 pb-0">
          <h5 class="text-center indigo--text">En Tránsito</h5>
        </v-card-title>
        <v-card-text class="justify-center align-content-center pa-1 pt-0">
          <h2 class="text-center">
            {{inTransit}}
          </h2>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col style="min-width: 150px" cols="1">
      <v-card outlined flat @click="updateStatus(3)" :disabled="this.$store.state.status.data.status === 3">
        <v-card-title class="justify-center align-content-center pa-1 pb-0">
          <h5 class="text-center indigo--text">Arribados</h5>
        </v-card-title>
        <v-card-text class="justify-center align-content-center pa-1 pt-0">
          <h2 class="text-center">
            {{arrived}}
          </h2>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col style="min-width: 150px" cols="1">
      <v-card outlined flat @click="updateStatus(4)" :disabled="this.$store.state.status.data.status === 4">
        <v-card-title class="justify-center align-content-center pa-1 pb-0">
          <h5 class="text-center indigo--text">Detenidos</h5>
        </v-card-title>
        <v-card-text class="justify-center align-content-center pa-1 pt-0">
          <h2 class="text-center">
            {{detained}}
          </h2>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col style="min-width: 150px" cols="1">
      <v-card outlined flat @click="updateStatus(5)" :disabled="this.$store.state.status.data.status === 5">
        <v-card-title class="justify-center align-content-center pa-1 pb-0">
          <h5 class="text-center indigo--text">Entregados</h5>
        </v-card-title>
        <v-card-text class="justify-center align-content-center pa-1 pt-0">
          <h2 class="text-center">
            {{delivered}}
          </h2>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col style="min-width: 170px; max-height: 88px;" cols="1">
      <v-card outlined flat @click="updateStatus(7)" :disabled="this.$store.state.status.data.status === 7" style="max-width: 167px">
        <v-card-title class="justify-center align-content-center pa-1 pb-0">
          <h5 class="text-center indigo--text">Pre entregados</h5>
        </v-card-title>
        <v-card-text class="justify-center align-content-center pa-1 pt-0">
          <h2 class="text-center">
            {{deliveredWithProvision}}
          </h2>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col style="min-width: 150px" cols="1">
      <v-card outlined flat @click="updateStatus(9)" :disabled="this.$store.state.status.data.status === 9">
        <v-card-title class="justify-center align-content-center pa-1 pb-0">
          <h5 class="text-center indigo--text">Terminados</h5>
        </v-card-title>
        <v-card-text class="justify-center align-content-center pa-1 pt-0">
          <h2 class="text-center">
            {{finished}}
          </h2>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "SenStatusBar",
  data: () => ({
    shipment: {
      id: null,
      orderId: null,
      transportationId: null,
      incoterm:null,
      users: [],
      pol: null,
      pod: null,
      origin: null,
      destination: null,
      shippingStatusId: null,
      orderFolio: null,
      containers: [],
      emails: [],
      routes: [],
      supplier: null,
    },
    operators: [],
    requiredSelectRule: [
      v => v.length > 0 || "Este campo es obligatorio"
    ],
    loading: false,
    provisional: 0,
    inTransit: 0,
    arrived: 0,
    detained: 0,
    delivered: 0,
    finished: 0,
    deliveredWithProvision:0,
  }),
  mounted() {
    this.getCount()
  },
  getOperators() {
    // this.$store.dispatch('working', true)

    this.$http.get('Employees/operators')
        .then(response => {
          this.operators = response.data.data;
        })
        .catch(error => {
          console.log(error)
        })
  },
  methods: {
    updateStatus(id){
      if (id === 1){
        this.$store.state.status.data.status = 1
      }
      if (id === 2){
        this.$store.state.status.data.status = 2
      }
      if (id === 3){
        this.$store.state.status.data.status = 3
      }
      if (id === 4){
        this.$store.state.status.data.status = 4
      }
      if (id === 5){
        this.$store.state.status.data.status = 5
      }
      if (id === 6){
        this.$store.state.status.data.status = 6
      }
      if (id === 7){
        this.$store.state.status.data.status = 7
      }
      if (id === 9){
        this.$store.state.status.data.status = 9
      }

    },
    getCount(){
      this.loading = true

      this.$http.get('Shipping/count')
          .then(response => {
            console.log(response, "aaaaaaa")
            this.provisional = response.data.data.provisional;
            this.inTransit = response.data.data.inTransit;
            this.arrived = response.data.data.arrived;
            this.detained = response.data.data.detained;
            this.delivered = response.data.data.delivered;
            this.finished = response.data.data.finished;
            this.deliveredWithProvision = response.data.data.deliveredWithProvision;
          })
          .catch(error => {
            console.log(error)
          }).finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
