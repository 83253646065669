var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shippingTransportationRoute},scopedSlots:_vm._u([{key:"item.transportationEventRoute",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.transportationEventRoute[0].name)+" ")]}},{key:"item.event",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEventDescription(item.event))+" ")]}},{key:"item.isEventStarted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isEventStarted ? 'Si' : 'No')+" ")]}},{key:"item.isEventCompleted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEventComplited(item.isEventCompleted))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }