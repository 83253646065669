<template>
  <div>
    <order-search-bar :filters="false" :status="8"
                      :url="'Shipping'"
                      style=" overflow-y: auto"
                      v-on:item-selected="selectOrder">
    </order-search-bar>
    <div>
      <v-container fluid no-gutters>
        <v-row style="margin-left: 21rem;">
          <sen-status-bar ref="statusBar" class="mb-2"></sen-status-bar>
        </v-row>
        <div v-if="shipment.shippingStatusId === 9" :style="{
          height: '100vh',
          marginLeft: '21rem',
          textAlign: 'center',
        }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mx-2 ml-16"
                      elevation="0"
                      color="primary"
                      fab
                      small
                      v-bind="attrs"
                      v-on="on"
                      :disabled="shipment.shippingStatusId !== 9"
                      @click="openShipment"
                  >
                    <v-icon dark>
                      mdi-lock-open-variant
                    </v-icon>
                  </v-btn>

                </template>
                <span>Reabrir Embarque</span>

              </v-tooltip>
            </div>
        <div :key="shipment.id" v-else>
          <v-row style="margin-left: 21rem;">
            <v-col cols="12" style="padding-left:0px">
              <v-card elevation="0" min-width="99%" outlined tile>
                <v-card-text>
                  <v-row class="d-flex justify-center max-height:500px">
                    <v-col class="overflow-x-auto pl-0">
                      <SenRouteTimeline v-model="shipment" @updateNotyClient="updateNotyClient"
                                        @updateOrder="selectOrder"></SenRouteTimeline>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-row style="margin-left: 21rem;">
          <test2 :shipment="shipment" :updateNotyClient="notiClient" @updateCarga="selectOrder"/>
        </v-row>
      </v-container>
    </div>

    <!--    <chat/>-->
    <chatSEN ref="chat" :ref-code="shipment.refCode"/>
  </div>
</template>

<script>
import OrderSearchBar from "@/components/Global/OrderSearchBar/OrderSearchBar";
import SenStatusBar from "@/views/Pages/Operation/components/SenStatusBar";
import SenRouteTimeline from "@/views/Pages/Operation/components/SenRouteTimeline";
import test2 from "./test2";
// import chat from "@/views/Pages/Operation/components/chat";
import chatSEN from "@/components/chatSEN"

export default {
  name: "OperationsPage",
  components: {
    SenRouteTimeline, SenStatusBar, OrderSearchBar, test2, chatSEN
  },
  data: (vm) => ({
    refCode: null,
    notiClient: null,
    lastId: null,
    isTerrestre0: false,
    IdOrder: null,
    shipment: {
      deliveryTypeId: null,
      shippingTransportationEvent: [],
      supplierId: null,
      ShipName: null,
      viaje: null,
      id: null,
      orderId: null,
      transportationId: null,
      transportationCategoryId: null,
      sale: null,
      incoterm: null,
      incotermId: null,
      users: [],
      pol: null,
      pod: null,
      masterBl: {},
      origin: null,
      destination: null,
      shippingStatusId: null,
      orderFolio: null,
      containers: [],
      emails: [],
      routes: [],
      supplier: null,
    },
    mini: !vm.$vuetify.breakpoint.xl,
  }),
  mounted() {
    let obj = {
      id: this.$route.params.id
    }
    this.selectOrder(obj)
  },
  methods: {
    openShipment(){
      this.shipmentWorking = true;
      const status = this.shipment.shippingStatusId === 9 ? 5 : 9;
      this.$http.put(`Shipping/${this.shipment.id}/status/${status}`)
          .then(response => {
            this.message = response.data.message
            console.log(response)
            if (response.data.code === 200) {
              this.$toast.info("Tu embarque fue reabierto!");
            } else {
              this.$toast.error(this.message)
            }
          }).catch(error => {
            console.log("=>(Operations.vue:135) error", error);
            this.$toast.error(this.message)
          }).finally(() => {
            this.shipmentWorking = false;
            this.selectOrder({id: this.shipment.id})
          })
    },
    updateNotyClient(bool) {
      this.notiClient = bool;
    },
    selectOrder(item) {
      this.$store.dispatch('working', true)
      this.$http.get('Shipping/' + item.id)
          .then(response => {
            // this.$refs.chat.closeChat();
            let respShipment = response.data.data;
            // this.shipment.masterBl.consignee = respShipment.masterBl.consignee
            if (respShipment.masterbl.id != 0) {
              if (respShipment.transportationId === 1) {
                if (respShipment.masterbl.shippingLine === null) {
                  respShipment.masterbl.shippingLine = {
                    supplierId: respShipment.purchase.details[0].supplierId,
                  }
                }
                if (respShipment.masterbl.shipper >= 1) {
                  if (respShipment.masterbl.shipper.forwarderId === null) {
                    respShipment.masterbl.shipper.forwarderId = respShipment.purchase.details[0].freightForwarderId
                  }
                }
                if (respShipment.masterbl.shipper === null) {
                  respShipment.masterbl.shipper = null
                }
                if (respShipment.masterbl.consignee === null) {
                  respShipment.masterbl.consignee = null
                }

              }
            }
            if (respShipment.transportationId === 2) {
              if (respShipment.masterbl.airline === null) {
                respShipment.masterbl.airline = {
                  supplierId: respShipment.purchase.details[0].supplierId,
                }
              }
              if (respShipment.masterbl.shipper === null) {
                respShipment.masterbl.shipper = null
              } else {
                if (respShipment.masterbl.shipper.forwarderId === null) {
                  respShipment.masterbl.shipper.forwarderId = respShipment.purchase.details[0].freightForwarderId
                }
              }
              if (respShipment.masterbl.consignee === null) {
                respShipment.masterbl.consignee = null
              }

            }
            if (respShipment.transportationId === 3) {
              if (respShipment.masterbl.shippingLine === null) {
                if (respShipment.purchase.details.length >= 1) {
                  respShipment.masterbl.shippingLine = {
                    supplierId: respShipment.purchase.details[0].supplierId
                  }
                }
              }
              if (respShipment.masterbl.shipper === null) {
                respShipment.masterbl.shipper = null
              }
              if (respShipment.masterbl.consignee === null) {
                respShipment.masterbl.consignee = null
              }
              // alert(respShipment.masterbl.houseBls.length == 0)
              if (respShipment.masterbl.houseBls == null) {
                respShipment.masterbl.houseBls = [{
                  refNumber: null,
                  shipper: {},
                  consignee: {},
                }]
              } else {
                if (respShipment.masterbl.houseBls.length == 0) {
                  respShipment.masterbl.houseBls = [{
                    refNumber: null,
                    shipper: {},
                    consignee: {},
                  }]
                }
              }
            }

            console.log(respShipment, 'respShipment')
            this.shipment.landTransfer = respShipment.landTransfer;
            this.shipment.externalRefNumber = respShipment.externalRefNumber;
            this.shipment.sendNotificationsToClient = respShipment.sendNotificationsToClient
            this.shipment.industry = respShipment.industry
            this.shipment.emails = respShipment.mails
            this.shipment.referenceNumber = Number(respShipment.referenceNumber)
            this.shipment.services = respShipment.services;
            this.shipment.client = respShipment.client;
            this.shipment.comments = respShipment.comments;
            this.shipment.transportationId = respShipment.transportationId;
            this.shipment.deliveryTypeId = respShipment.deliveryTypeId
            this.shipment.status = respShipment.status;
            this.shipment.shippingTransportationEvent = respShipment.shippingTransportationEvent;
            this.shipment.orderType = respShipment.orderType;
            this.shipment.users = respShipment.users;
            this.shipment.commodity = respShipment.commodity;
            this.shipment.clientSupplierName = respShipment.clientSupplierName;
            this.shipment.contactName = respShipment.contactName;
            this.shipment.referenceNumber = respShipment.referenceNumber
            this.shipment.transportationCategory = respShipment.transportationCategory;
            this.shipment.businessId = respShipment.businessId;
            this.shipment.additionalInfo = respShipment.additionalInfo;
            this.shipment.documents = respShipment.documents;
            this.shipment.refCode = respShipment.refCode;
            this.shipment.orderId = respShipment.orderId;
            this.shipment.IdQuotation = respShipment.IdQuotation;
            this.shipment.transportationcategoryId = respShipment.transportationCategoryId;
            this.shipment.transportationCategoryId = respShipment.transportationCategoryId;
            this.shipment.orderTypeId = respShipment.orderTypeId;
            this.shipment.incotermId = respShipment.incotermId;
            this.shipment.sale = respShipment.sale;
            this.shipment.totalPurchase = respShipment.totalPurchase;
            this.shipment.incoterm = respShipment.incoterm;
            this.shipment.quotationId = respShipment.quotationId;
            this.shipment.id = respShipment.id;
            this.shipment.totalPurchaseUsd = respShipment.totalPurchaseUsd
            this.shipment.totalSaleUsd = respShipment.totalSaleUsd
            if (respShipment.purchase.details.length >= 1) {
              this.shipment.supplierId = respShipment.purchase.details[0].supplierId;
            }
            if (this.shipment.transportationId == 3) {
              if (respShipment.incoterm === null) {
                this.shipment.incoterm = null
              } else {
                this.shipment.incoterm = respShipment.incoterm.code;
              }
            }

            this.shipment.deliveryType = respShipment.deliveryType;
            // TODO por aqui validamos si es un embarcque unficado
            if (respShipment.masterbl.id == 0) {
              console.log('entro al 0')
              if (respShipment.transportationId === 1) {
                this.shipment.masterBl = { // <= note the parenthesis
                  shippingId: null,
                  supplierId: respShipment.purchase.details[0].supplierId,
                  shipName: null,
                  delaysInPort: null,
                  storageDays: null,
                  tripNumber: null,
                  shipper: null,
                  shippingLine: {
                    supplierId: respShipment.purchase.details[0].supplierId,
                  },
                  airline: {
                    supplierId: null,
                  },
                  additionalInfo: [{
                    flightNumber: null
                  }],
                  cargoQuantity: null,
                  cargoVolume: null,
                  cargoWeight: null,
                  consignee: null,
                  bookingNumber: null,
                  documents: [],
                  houseBls: [{
                    shipper: {
                      supplierId: respShipment.purchase.details[0].supplierId,
                      forwarderId: null,
                    },
                    consignee: respShipment.masterbl.consignee,
                    refNumber: respShipment.referenceNumber,
                    BlTypeId: null,
                    CustomAgentId: null,
                  }],
                  id: null,
                  status: false
                }
                if (respShipment.masterbl.shipper !== null) {
                  this.shipment.masterBl.shipper = respShipment.masterbl.shipper
                }
              }
              if (respShipment.transportationId === 2) {
                this.shipment.masterBl = { // <= note the parenthesis
                  shippingId: null,
                  supplierId: respShipment.purchase.details[0].supplierId,
                  shipName: null,
                  tripNumber: null,
                  shipper: [{
                    forwarderId: respShipment.purchase.details[0].freightForwarderId,
                  }],
                  shippingLine: {
                    supplierId: null,
                  },
                  airline: {
                    supplierId: respShipment.purchase.details[0].supplierId,
                  },
                  additionalInfo: [{
                    flightNumber: null
                  }],
                  cargoQuantity: null,
                  cargoVolume: null,
                  cargoWeight: null,
                  consignee: [{
                    forwarderId: null,
                  }],
                  delaysInPort: null,
                  storageDays: null,
                  bookingNumber: null,
                  documents: [],
                  houseBls: [{
                    shipper: {
                      supplierId: respShipment.purchase.details[0].supplierId,
                      forwarderId: null,
                    },
                    consignee: respShipment.masterbl.consignee,
                    clientId: null,
                    supplierId: null,
                    refNumber: respShipment.referenceNumber,
                    BlTypeId: null,
                    CustomAgentId: null,
                  }],
                  id: null,
                  status: false
                }
              }
              if (respShipment.transportationId === 3) {
                this.shipment.masterBl = { // <= note the parenthesis
                  shippingId: null,
                  supplierId: respShipment.purchase.details[0].supplierId,
                  shipName: null,
                  delaysInPort: null,
                  storageDays: null,
                  refNumber: null,
                  tripNumber: null,
                  shipper: null,
                  shippingLine: {
                    supplierId: respShipment.purchase.details[0].supplierId,
                  },
                  airline: {
                    supplierId: null,
                  },
                  additionalInfo: [{
                    flightNumber: null
                  }],
                  cargoQuantity: null,
                  cargoVolume: null,
                  cargoWeight: null,
                  consignee: null,
                  bookingNumber: null,
                  documents: [],
                  houseBls: [{
                    shipper: null,
                    consignee: null,
                    refNumber: respShipment.referenceNumber,
                    BlTypeId: null,
                    CustomAgentId: null,
                  }],
                  id: null,
                  status: false
                }
                // aqui van cosas
                if (this.shipment.masterBl.shipper === null) {
                  respShipment.masterbl.shipper = {
                    supplier: respShipment.purchase.details[0].supplier,
                    supplierId: respShipment.purchase.details[0].supplier.id,
                  }
                }
                console.log(respShipment.masterbl.houseBls, 'resp')
                console.log(respShipment.masterbl.houseBls[0], 'resp')
                this.shipment.masterBl.houseBls[0].consignee = {
                  clientId: respShipment.clientId,
                  supplier: respShipment.client
                }
                this.shipment.masterBl.houseBls[0].consignee.supplier.companyName = this.shipment.masterBl.houseBls[0].consignee.supplier.name
                this.isTerrestre0 = true
                this.shipment.masterBl.houseBls[0].shipper = {
                  forwarderId: null,
                  clientBuyerId: null,
                  clientId: null,
                  supplierId: null,
                  clientSupplierId: null
                }
              }
            } else {
              this.shipment.masterBl = respShipment.masterbl;
            }

            this.shipment.cargo = []
            this.shipment.cargo = respShipment.cargo
            let arr = []
            this.shipment.cargo.push(arr)
            this.shipment.cargo.splice(-1)
            if (respShipment.masterbl?.id != 0) {

              console.log(this.shipment)
              this.shipment.masterBl.consignee = respShipment.masterbl?.consignee
              if (this.shipment.masterBl.shipper != null) {
                console.log("hola")
                if (respShipment.transportationId === 1 || respShipment.transportationId === 2) {

                  // this.shipment.masterBl.shipper.forwarderId = parseInt(this.shipment.masterBl.shipper.forwarderId);
                  console.log('este es el de operations', this.shipment.masterBl)
                  if (this.shipment.masterBl.consignee === null) {
                    console.log('jajanul')
                  } else {
                    this.shipment.masterBl.consignee.forwarderId = parseInt(this.shipment.masterBl.consignee.forwarderId);
                  }
                }
              }
              this.shipment.cargo = []
              this.shipment.cargo = respShipment.cargo
              let arr = []
              this.shipment.cargo.push(arr)
              this.shipment.cargo.splice(-1)
              if (this.shipment.cargo.length >= 0) {
                this.shipment.cargo.forEach((element, index) => {
                  if (element.containerNumber) {
                    let aux = element.containerNumber.split(';');
                    this.shipment.cargo[index].containerNumber = aux
                  } else {
                    this.shipment.cargo[index].containerNumber = null
                  }
                  if (element.containerSeals) {
                    let aux = element.containerSeals.split(';');
                    this.shipment.cargo[index].containerSeals = aux
                  } else {
                    this.shipment.cargo[index].containerSeals = null
                  }
                  if (element.truckNumber) {
                    let aux = element.truckNumber.split(';');
                    this.shipment.cargo[index].truckNumber = aux;
                  } else {
                    this.shipment.cargo[index].truckNumber = null
                  }

                })
                console.log(this.shipment.cargo)
              } else {
                this.shipment.cargo = []
              }
            }
            this.shipment.shippingStatusId = respShipment?.shippingStatusId;
            /*this.shipment.users = respShipment.users.map((user) => {
              return user.userId;
            });*/
            this.shipment.client = respShipment?.client;

            if (respShipment.routes.length > 0) {
              this.shipment.routes = respShipment.routes;
            } else {
              this.shipment.routes = []
            }


            if (respShipment.masterbl.houseBls === null) {
              this.shipment.masterBl.houseBls = []
            } else {
              console.log('entro al map')
              if (!this.isTerrestre0) {
                this.shipment.masterBl.houseBls = respShipment.masterbl.houseBls
              }
              console.log('beforemap', this.shipment)
              this.shipment.masterBl.houseBls = this.shipment.masterBl.houseBls.map((element) => {
                return {
                  ...element,
                  mEmbarcador: null,
                  mSearch: null,
                  mItems: [],
                  mFirst: false,


                  cConsig: null,
                  cSearch: null,
                  cItems: [],
                  cFirst: false,
                }
              })
              console.log('aftermap', this.shipment)
            }
            if (item.id === this.lastId) {
              this.shipment.same = true
            } else {
              this.shipment.same = false
            }
            this.lastId = item.id
            this.$router.push(`/Operation/${this.shipment.id}`)

            console.log("Shipment", this.shipment);
          })
          .catch(error => {
            console.log(error)
          }).finally(() => this.$store.dispatch('working', false))
    },
  }
}
</script>

<style scoped>
/*.timeline {
  display: block;
  position: relative;
}*/

>>> .timeline .v-input__slot {
  padding: 0 6px !important;
}

>>> .timeline .v-input__slot .v-label {
  margin-left: 6px;
}

.wrap {
  margin-left: 21rem;
  margin-right: 3.5rem;
}

.wrap_xl {
  margin-left: 21rem;
  margin-right: 27rem;
}

>>> .v-expansion-panel-content__wrap {
  padding-left: 0px;
}

>>> .v-alert__icon.v-icon {
  margin-top: 6px;
}

</style>
