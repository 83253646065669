import { render, staticRenderFns } from "./test2.vue?vue&type=template&id=5b7a6d30"
import script from "./test2.vue?vue&type=script&lang=js"
export * from "./test2.vue?vue&type=script&lang=js"
import style0 from "./test2.vue?vue&type=style&index=0&id=5b7a6d30&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VSpacer,VTab,VTabItem,VTabs})
