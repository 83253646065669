<template>
  <v-data-table
      :headers="headers"
      :items="shippingTransportationRoute"
      class="elevation-1"
  >
    <template v-slot:item.transportationEventRoute="{ item }">
      {{ item.transportationEventRoute[0].name }}
    </template>

    <template v-slot:item.event="{ item }">
      {{ getEventDescription(item.event) }}
    </template>

    <template v-slot:item.isEventStarted="{ item }">
      {{ item.isEventStarted ? 'Si' : 'No'}}
    </template>

    <template v-slot:item.isEventCompleted="{ item }">
      {{ getEventComplited(item.isEventCompleted) }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "routeInfo",
  props: {
    shipment: {
      type: Object,
    },
  },
  data () {
    return {
      shippingTransportationRoute: [],
      headers: [
        { text: 'Posicion', value: 'position' },
        {
          text: 'Evento',
          align: 'start',
          value: 'eventDescription',
        },
        { text: 'Dia de salida', value: 'departureDate' },
        { text: 'Dia de llegada', value: 'arrivalDate' },
        { text: 'Evento comenzo', value: 'isEventStarted' },
        { text: 'Evento finalizado', value: 'isEventCompleted' },
        { text: 'Supplier', value: 'supplier.companyName' },
        { text: 'country', value: 'transportationEventRoute' },

      ],
    }
  },
  mounted() {
    this.shippingTransportationRoute = this.shipment.shippingTransportationEvent;
    this.getEventDescription();
  },
  watch: {
    shipment: {
      handler() {
        this.shippingTransportationRoute = null;
        this.shippingTransportationRoute = this.shipment.shippingTransportationEvent;
        this.getEventDescription()
      },
      deep: true
    }
  },
  methods: {
    getEventDescription(event) {
      switch (event) {
        case 'CLL':
          return 'Container Loaded at first POL (Port of Load)';
        case 'VDT':
          return 'Vessel departure from T/S (Transhipment Port)';
        case 'VAT':
          return 'Vessel arrival at T/S port (Transhipment Port)';
        case 'CDT':
          return 'Container discharge at T/S port (Transhipment Port)';
        case 'VDL':
          return 'Vessel departure from first POL (Port of Load)';
        case 'CDD':
          return 'Container discharge at final POD (Port of Discharge)';
        case 'VAD':
          return 'Vessel arrival at final POD (Port of Discharge)';
        case 'CLT':
          return 'Container loaded at T/S port (Transhipment Port)';
        case 'UNK':
          return 'Unknown';
        case 'LTS':
          return 'Land transhipment';
        case 'BTS':
          return 'Barge transhipment';
        case 'CEP':
          return 'Container empty to shipper';
        case 'CPS':
          return 'Container pickup at shipper';
        case 'CeL':
          return 'Container gate in at POL (Port of Load)';
        case 'TSD':
          return 'Transhipment delay';
        case 'CDC':
          return 'Container delivery to consignee';
        case 'CER':
          return 'Container empty return to depot';
        case 'CGO':
          return 'Container gate out at POD (Port of Discharge)';
        default:
          return 'NA';
      }
    },
    getEventComplited(event){
      switch (event){
        case true:
          return 'Si'
        default:
          return 'No';
      }
    }
  },
}
</script>

<style scoped>

</style>
