<template>
  <v-navigation-drawer permanent :width="width" absolute>
    <v-toolbar tile elevation="0">
      <v-text-field
          v-model="search"
          class="my-2"
          dense
          label="Buscar..."
          @input="SearchOrder(search)"
          single-line
          hide-details
      >
        <template v-slot:append>
          <v-menu
              offset-y
              content-class="included"
              :close-on-content-click="false"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                      icon
                      small
                      class="mx-1"
                      v-bind="attrs"
                      v-on="{ ...menu, ...tooltip }"
                      @click="getThings"
                  >
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                Buscar por...
              </v-tooltip>
            </template>
            <v-card width="300">
              <v-card-text>
                <h2 class="font-weight-regular">Filtrar</h2>
                <v-row>

                  <v-col md="12">
                    <v-row justify="center">
                      <v-col md="12">
                        <v-autocomplete
                            label="Cliente"
                            v-model="filterCustomer"
                            :items="Customers"
                            dense
                            item-value="id"
                            item-text="name"
                            clearable
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="12">
                    <v-row justify="center">
                      <v-col md="12">
                        <v-autocomplete
                            label="Incoterm"
                            v-model="filterIncoterm"
                            :items="incoterms"
                            item-text="code"
                            item-value="id"
                            dense
                            clearable
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="12">
                    <v-row justify="center">
                      <v-col md="12">
                        <v-text-field
                            label="RefNumber"
                            v-model="filterRefnumber"
                            dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="12">
                    <v-row justify="center">
                      <v-col md="12">
                        <v-text-field
                            label="Ref SoftCargo"
                            v-model="refSoft"
                            dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="12">
                    <v-row justify="center">
                      <v-col md="12">
                        <v-select
                            label="Order By"
                            v-model="orderBy"
                            :items="orderOptions"
                            item-text="label"
                            item-value="name"
                            dense
                            clearable
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col md="12">
                    <v-checkbox class="pt-3" v-model="filterUrgent" label="Urgente"></v-checkbox>
                  </v-col>

                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="filterOrders" color="primary">Buscar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </template>
      </v-text-field>
    </v-toolbar>
    <v-divider></v-divider>
    <InfiniteScroll v-bind="infiniteScrollProps" @loadMoreItems="GetData">
      <template v-slot="{ item,index }">
        <v-list-item-group
            :value="selectedItemOrder"
            active-class="grey lighten-2"

        >
          <v-list-item @click="HandleClick(item)" class="pa-0">
            <v-list-item-content class="py-0">
              <v-list-item-title>
                <v-card class="pa-0"
                        :color="index === selectedItemOrder ? 'grey lighten-2': ''"
                >
                  <v-card-title class="pt-0 pb-0 pr-0 pl-2">
                    <v-row no-gutters>
                      <v-col md="8">

                        <v-icon v-if="item.urgent" color="red">mdi-alert</v-icon>

                        <v-row
                            no-gutters
                        >

                          <v-tooltip top v-if="item.refCode ? item.refCode.length > 18 : item.id">
                            <template v-slot:activator="{on, attrs}">
                              <span class="font-weight-bold text-button mt-0 pt-0" v-bind="attrs"
                                    v-on="on">{{
                                  item.refCode ? item.refCode.substring(0,18) : item.id
                                }}</span>
                              <span v-if="item.refCode ? item.refCode.length > 18 : item.id">...</span>
                            </template>
                            <span class="font-weight-bold text-button mt-0 pt-0" >{{
                                item.refCode ? item.refCode : item.id
                              }}</span>
                          </v-tooltip>
                          <span class="font-weight-bold text-button mt-0 pt-0" v-else>{{
                              item.refCode ? item.refCode.substring(0,18) : item.id
                            }}</span>


                        </v-row>
                        <v-row no-gutters>
                          <span class="font-weight-regular text-subtitle-2">
  {{ item.operatorName }} - {{ item.clientName }}
</span>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="3">
                            <v-icon
                                large
                                dense
                                color="primary"
                                v-if="item.transportationId === 1"
                            >
                              mdi-ferry
                            </v-icon>
                            <v-icon
                                large
                                dense
                                color="red"
                                v-if="item.transportationId === 2"
                            >
                              mdi-airplane
                            </v-icon>
                            <v-icon
                                large
                                dense
                                color="green"
                                v-if="item.transportationId === 3"
                            >
                              mdi-truck
                            </v-icon>
                          </v-col>
                          <v-col cols="3">
                             <span class="font-weight-bold text-subtitle-1 mt-16">{{
                                 item.incoterm ? item.incoterm.code : ''
                               }}</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="4" class="d-flex align-end flex-column">
                        <span class="font-weight-regular text-caption pa-2 mt-auto">{{
                            item.createdAt | luxon({
                              output: {
                                zone: "local",
                                format: "dd-MM-yyyy"
                              }
                            })
                          }}</span>
                <span class="font-weight-regular text-caption pa-2 mt-auto">{{
                    item.mblRefNumber
                  }}</span>
                        <span class="font-weight-regular text-caption pa-2 mt-auto">{{
                            item.shippingStatus
                          }}</span>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-list-item-title>
              <v-divider color="grey"></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </template>
    </InfiniteScroll>
  </v-navigation-drawer>
</template>

<script>
import debouncePromise from "debounce-promise";
import InfiniteScroll from "../../InfiniteScroll";
import folio_mixin from "@/mixins/folio_mixin";

export default {
  name: "OrderSearchBar",
  mixins: [folio_mixin],
  components: {InfiniteScroll},
  props: {
    url: {
      type: String,
      default: "Order",
    },
    width: {
      type: [Number, String],
      default: "21rem",
    },
    status: Number,
    filters: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    refSoft: null,
    filterRefnumber: null,
    totalPages: 2,
    infiniteScrollProps: {
      page: 1,
      pageSize: 5,
      items: [],
      lastPage: null,
    },
    shipmentId: null,
    id: null,
    options: {
      page: 0,
      itemsPerPage: 20,
      pageStart: 0,
      pageStop: 0,
      pageCount: 0,
      itemsLength: 0,
    },
    show: false,
    filter: "",
    search: "",
    customerFilterMenu: false,
    Data: [],
    Customers: [],
    salesManagerFilterMenu: false,
    incoterms: [],
    pricerFilterMenu: false,
    Quotation: [],
    orderOptions: [
      {
        name: 'LatestMessageAt',
        label: 'Ultimo mensaje'
      },
      {
        name: 'UpdatedAt',
        label: 'Ultimo Actualizado'
      },
      {
        name: 'CreatedAt',
        label: 'Ultimo creado'
      }
    ],
    orderBy: 'UpdatedAt',
    OrderStatus: [
      {
        id: 1,
        name: "Cotización Pendiente",
      },
      {
        id: 2,
        name: "Pendiente Pricing",
      },
      {
        id: 3,
        name: "Solicitado a Proveedor",
      },
      {
        id: 4,
        name: "Enviado a Ventas",
      },
      {
        id: 5,
        name: "Cotizacion Incompleta",
      },
      {
        id: 6,
        name: "Enviada a cliente",
      },
      {
        id: 7,
        name: "Renegociación",
      },
      {
        id: 8,
        name: "Vendida",
      },
      {
        id: 9,
        name: "Cancelada",
      },
      {
        id: 10,
        name: "Rechazada",
      },
    ],
    filterStatus: null,
    filterIncoterm: null,
    filterQuotationId: null,
    filterCustomer: null,
    colorStatus: [
      {
        status: "Enviado a Ventas",
        color: "green darken-2",
      },
      {
        status: "Pendiente Pricing",
        color: "yellow darken-2",
      },
      {
        status: "Solicitado a Proveedor",
        color: "red darken-2",
      },
    ],
    filterUrgent: null,
    UrgentShipments: null
  }),
  computed: {
    selectedItemOrder: {
      get: function () {
        return this.Data.findIndex(x => x.id == this.orderId)
      }
    },
    orderId: {
      get: function () {
        return this.$route.params.id
      }
    }
  },
  mounted() {
    this.filterStatus = this.status;
    //this.GetDataOrder();
    // this.filterOrders();
    //this.GetQuotations();
    // this.getCustomers();
    // this.getIncoterms();
    //this.getPricer();

    //this.HandleClick(this.selectedItemOrder);
  },
  watch: {
    "$store.state.status.data.status"(nv) {
      if (this.$store.state.status.data.status === 0) {
        console.log('h')
      } else {
        this.myprop = nv;
        console.log(this.$store.state.status.data.status);
        this.getOrders('', this.$store.state.status.data.status)
      }

    },
  },
  methods: {
    getUserFirstName(item) {
      if (item && item.users && item.users[0] && item.users[0].user) {
        return item.users[0].user.firstName;
      }
      return 'N/A'; // Or any default value if the property doesn't exist
    },
    getThings() {
      this.getCustomers();
      this.getIncoterms();
    },
    RedirectPrices(item) {
      this.Itemid = item.Id;
      let index = this.Data.findIndex(element => element.Id == item.Id);
      this.Data[index].Notifications = 0;
      if (this.priceLength > 0 && this.statusOrder == "Pendiente Pricing") {
        this.dialog = true;
        this.dialogslot = 7;

      } else {
        this.Redirect();
      }
    },


   async GetData(page=1){
      // Make first two requests
      const [firstResponse, secondResponse] = await Promise.all([
        this.$http.get(
            'shipping/Find', {
              params: {
                Priority:'URGENT'
              }
            }
        ),
        this.$http.get('shipping/Find', {
          params: {
            PageSize: this.options.itemsPerPage,
            PageNumber: page,
            Pricing: this.filterPricer,
            SalesManager: this.filterSalesman,
            Client: this.filterCustomer,
            OrderBy: this.orderBy // Add this line to include the OrderBy parameter
          }
        })

      ]);
      let Urgents = [];
      Urgents = firstResponse.data.data;

      let NoUrgents = [];
      NoUrgents = secondResponse.data.data;


      Urgents = Urgents.map(item=>{
        return {
          ...item,
          urgent: true
        }
      });

     const mergeResult = [...Urgents, ...NoUrgents];

     this.Data = mergeResult;

     //this.totalPages = NoUrgents.totalPages
//
//
     //this.infiniteScrollProps.page = page;
//
     //this.infiniteScrollProps.lastPage = secondResponse.data.meta.totalPages;
//
     this.infiniteScrollProps.items = this.Data;


   },

    /*GetData: function (page = 1) {
      if (page <= this.totalPages) {

        this.$http.get('shipping/Find', {
          params: {
            PageSize: this.options.itemsPerPage,
            PageNumber: page,
            Pricing: this.filterPricer,
            SalesManager: this.filterSalesman,
            Client: this.filterCustomer,
            OrderBy: this.orderBy // Add this line to include the OrderBy parameter
          }
        }).then(response => {
          if (response.data.code == 200) {
            console.log(response)
            if (response.data.data.length === 0) {
              return
            } else {
               this.Data = response.data.data;
              this.totalPages = response.data.meta.totalPages

              this.$nextTick(() => {
                this.isLoading = false;
              })


              this.infiniteScrollProps.page = page;

              this.infiniteScrollProps.lastPage = response.data.meta.totalPages;

              this.infiniteScrollProps.items = this.Data;
            }



          }


          // this.orderByNotifications();


          if (response.data.code == 400 || response.data.code == 500)
            this.$toast.error("Algo salio mal, intente mas tarde")

        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.$store.dispatch('working', false);
        })
        this.active = 0;
      }
    },*/
    SearchOrder: debouncePromise(function (e) {
      console.log(e);
      this.getOrders(e);
    }, 1000),
    getOrders(e, status) {
      if (e) {
        this.$http.get(`shipping/Find?Value=${e}`).then((response) => {
          console.log(response);
          this.Data = response.data.data;
          this.infiniteScrollProps.items = this.Data
          //this.infiniteScrollProps.lastPage = response.data.meta.totalPages;
          //this.infiniteScrollProps.page = response.data.meta.currentPage;
          this.$store.state.status.data.status = 0
        });
      } else {
        console.log(status)
        this.$http.get(`shipping/Find?ShippingStatusId=${status}`).then((response) => {
          console.log(response);
          this.Data = response.data.data;
          //this.infiniteScrollProps.items = this.Data
          //this.infiniteScrollProps.lastPage = response.data.meta.totalPages;
          //this.infiniteScrollProps.page = response.data.meta.currentPage;

        });
      }

    },
    initializeDefaultShipment(data) {
      this.shipmentId = this.$route.params.id;
      console.log(this.Data);
      let item = data.find((x) => x.id == this.shipmentId);
      console.log("item", item);
      this.$emit("item-selected", item);
    },

    getIncoterms() {
      this.$store.dispatch("working", true);

      this.$http
          .get("Incoterms")
          .then((response) => {
            this.incoterms = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => this.$store.dispatch("working", false));
    },
    getPricer() {
      this.$store.dispatch("working", true);

      this.$http
          .get("Quotation")
          .then((response) => {
            this.Quotation = response.data.data;
            console.log(this.Quotation);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => this.$store.dispatch("working", false));
    },
    getCustomers() {
      this.$store.dispatch("working", true);

      this.$http
          .get("Companies")
          .then((response) => {
            console.log(response);
            this.Customers = response.data.data;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit("reset");
              this.$toast.error("La sesion expiro");
              this.$router.replace("/login");
            }
          })
          .finally(() => this.$store.dispatch("working", false));
    },
    GetDataOrder() {
      this.$emit("idOrder", this.id);

      console.log("Hello");

      this.$store.dispatch("working", true);

      this.$http
          .get(`Orders/${this.id}`)
          .then((response) => {
            console.log(response);
            this.DataOrder = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
          });
    },
    GetQuotations() {
      this.$http
          .get(`Quotation/Order/${this.id}`)
          .then((response) => {
            if (response.data.code == 200) {
              if (response.data.data.length > 0) {
                this.Quotations = response.data.data;
              }

              if (response.data.data.length == 0) {
                this.AddQuotation();
              }
            }

            if (response.data.code == 400 || response.data.code == 500)
              this.$toast.error("Algo salio mal, intente mas tarde");
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$store.commit("reset");
              this.$toast.error("La sesion expiro");
              this.$router.replace("/login");
            }
            console.log(error);
          })
          .finally(() => {
          });
    },
    filterOrders() {
      this.isLoading = true;
      this.filterBy = {};

      if (this.refSoft) {
        this.filterBy.externalRefNumber = this.refSoft;
      }
      if (this.filterCustomer) {
        this.filterBy.ClientId = this.filterCustomer;
      }
      if (this.filterIncoterm) {
        this.filterBy.IncotermId = this.filterIncoterm;
      }
      if (this.filterRefnumber) {
        this.filterBy.MasterRefNumber = this.filterRefnumber;
      }
      if (this.orderBy) {
        this.filterBy.OrderBy = this.orderBy; // Add this line to include the OrderBy parameter
      }


      this.$store.dispatch("working", true);

      let apiUrl = "shipping/Find";
      this.$http
          .get(apiUrl, {
            params: this.filterBy
          })
          .then((response) => {
            console.log(response.data.data);
            this.Data = response.data.data;
            this.infiniteScrollProps.items = this.Data;
            console.log("cliente 0", this.Data[0].client);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.dispatch("working", false);
            this.isLoading = false;
          });
    },

    SearchQuote(searchValue) {
      if (searchValue == "") this.filter = false;
      else if (searchValue != "") {
        let find = this.Data.find((f) => f.folio.includes(searchValue));

        this.aux = [];
        this.aux.push(find);
        this.filter = true;
      }
    },
    HandleClick(item) {
      this.$emit("item-selected", item);
      console.log(item.id)
      this.shipmentId = item.id;
    },
    infiniteScrolling(intersect) {
      if (intersect[0].isIntersecting && this.Data.length > 15) {
        if (!this.isLoading) {
          this.isLoading = true;
          this.options.page += 1;
          this.GetData(this.options.page);
        }
      }
    },
  },
};
</script>

<style scoped></style>
