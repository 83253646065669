<template>
  <v-menu
      top
      v-model="menuStatus"
      :offset-y="true"
      :close-on-click="false"
      :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="mx-2 mainBottom"
          fab
          dark
          color="blue darken-4"
          v-bind="attrs"
          v-on="on"
      >
        <v-icon dark>
          mdi-chat
        </v-icon>
      </v-btn>
    </template>
    <div class="mainContainer">
      <div class="topChat">
        <div class="d-flex justify-space-between">
          <div class="d-flex justify-center align-center">
            <v-icon
                color="blue darken-4"
                class="mr-1 ml-2"
            >
              mdi-account-group
            </v-icon>
            <h4>Viewable By: </h4>
            <v-chip
                class="ma-2"
                color="primary"
                small
            >
              {{ members.length }}
            </v-chip>
          </div>
        </div>
      </div>
      <div class="middleChat" v-if="!isDragOver" >
        <div
          @dragover="dragOver"
            v-for="(message, index) in messages"
            :key="index"
            class="ma-2 messageContainer"
            :id="message.id"
        >
        <div class="insideMessage">
        {{ members.find((x) => x.userId === message.userId).firstName || ""}}
        <br>
          <div v-if=" message.files && message.files.length > 0" class="d-flex justify-center align-content-center">
            {{message.text}}
            <div @click="downloadDoc(file)" v-for="(file, index) in message.files" :key="index" class="filesChat d-flex justify-center align-content-center">
              <v-icon
                  style="color: #0d4d98;"
                  large
              >mdi-file-outline</v-icon>
              <div v-if="file">
                <h5 v-if="file.length > 9" style="color: #0d4d98" class="d-flex justify-center align-content-center">
                  {{ file.substring(0, 8) + '...' + file.substring(file.length - 3) }}
                </h5>
                <h5 v-else style="color: #0d4d98" class="d-flex justify-center align-content-center">
                  {{ file }}
                </h5>
              </div>
            </div>
          </div>
          <div v-else>
            {{message.text}}
          </div>
        </div>
        </div>
      </div>
      <div class="middleChat d-block dragOverC d-flex flex-column justify-center align-center" v-else-if="isDragOver">
        <h5>¡Suelta tus documentos aqui!</h5>
          <input @change="onChange" multiple type="file" class="inputStyle" />
        </div>
      <div class="bottomChat">
        <v-text-field
            v-model="messageToSend.message"
            :prepend-icon="'mdi-file-document-multiple-outline'"
            :append-outer-icon="'mdi-send'"
            filled
            clear-icon="mdi-close-circle"
            clearable
            label="Escribe algo..."
            type="text"
            @click="defaultTabName"
            @click:append-outer="sendMessage"
            @click:prepend="openFile"
            @click:clear="clearMessage"
        ></v-text-field>
      </div>
    </div>
  </v-menu>
</template>

<script>

export default {
  name: "chatSEN",
  props: {
    refCode: String,
  },
  data:() => ({
    isDragOver: false,
    menuStatus: false,
    members: [],
    messages: [],
    messageToSend: {
      message: null,
      files: []
    },
  }),
/*  mounted() {
    this.joinRoom();
  },*/
  watch: {
    menuStatus: {
      handler() {
        if (this.menuStatus) {
          this.scrollToMessage();
        }
      }
    },
    refCode: {
      handler() {
        this.startChat();
      }
    }
  },
  methods: {
    getType(file) {
      const regex = /\.([a-zA-Z0-9]+)$/;
      const match = file.match(regex);
      match[1] = match[1].toLowerCase();
      if (match[1] === 'gif') {
        return 'fa-gift'
      }
      else if (match[1] === 'png') {
        return 'fa-file-image'
      }
      else if (match[1] === 'jpg') {
        return 'fa-file-image'
      }
      else if (match[1] === 'jpeg') {
        return 'fa-file-image'
      }
      else if (match[1] === 'pdf') {
        return 'fa-file-pdf'
      }
      else if (match[1] === 'mp3') {
        return 'fa-file-audio'
      }
      else if (match[1] === 'mp4') {
        return 'fa-file-audio'
      }
      else if (match[1] === 'xls') {
        return 'fa-file-excel'
      }
      else if (match[1] === 'xlsx') {
        return 'fa-file-excel'
      }
      else if (match[1] === 'ccv') {
        return 'fa-file-excel'
      }
      else if (match[1] === 'txt') {
        return 'fa-file'
      }
      else if (match[1] === 'doc') {
        return 'fa-file-word'
      }
      else if (match[1] === 'docx') {
        return 'fa-file-word'
      }
      else if (match[1] === 'ppt') {
        return 'fa-file-power-point'
      }
      else {
        return 'fa-file'
      }
    },
    downloadDoc(file) {
      this.$signalChat.get(`api/Chat/enpointDownloadUrl?roomId=${this.roomId}&fileName=${file}`).then((response) => {
        console.log(response)

        fetch(response.data)
            .then(response => response.blob())
            .then(blob => {
              const downloadLink = document.createElement('a');
              downloadLink.href = URL.createObjectURL(blob);
              downloadLink.download = file; // Change this to the desired filename
              downloadLink.style.display = 'none';

              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            })
            .catch(error => {
              console.error('Error fetching or downloading the image:', error);
            });

        /*const regex = /\.([a-zA-Z0-9]+)$/;
        const match = file.match(regex);
        match[1] = match[1].toLowerCase();

        const blob = new Blob([response.data], {type: this.getType(file)})
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = blobUrl;
        // a.target = '_blank';
        a.download = file;
        document.body.appendChild(a);
        // document.body.appendChild(a);
        // a.style.display = 'none';
        a.click();
        a.remove();

        URL.revokeObjectURL(blobUrl);*/
        /*const newWindow = window.open(response.data, '_blank');
        if (newWindow) {
          newWindow.focus();
        }*/
      })
    },
    closeChat() {
      this.menuStatus = false;
    },
    dragOver() {
      this.isDragOver = true;
    },
    async startChat() {
      this.clearMessages();
      await this.joinRoom();
    },
    clearMessages() {
      this.messages = []
      this.messageToSend.message = null
    },
    scrollToMessage() {
      setTimeout(() => {
      let id = this.messages[this.messages.length - 1].id;
      document.getElementById(id).scrollIntoView({behavior: 'smooth'});
      }, 750)
    },
    getMessages(roomId) {
      this.$signalChat.get('api/Chat/messages/room/' + roomId + '?pageNumber=1&pageSize=10').then((response) => {
        this.messages = response.data.messages.reverse();
        this.members = response.data.users;
      }).catch((error) => {
        console.log(error)
      })
    },
    defaultTabName () {
      window.document.title = 'Continente Cargo'
    },
    joinRoom() {
      this.$hubChat.Connection.invoke('JoinChatRoom', this.refCode).then( async (response) => {
        this.roomId = response.roomId;
        await this.getMessages(response.roomId);
        /* this.$hubChat.Connection.on('ReceiveMessage','8', this.roomId, (message) => {
          console.log(message)
        }) */
        this.$hubChat.Connection.on('ReceiveMessage', (resp) => {
           console.log(resp);
          let id = this.$store.getters.UserId.toString();
          const msg = JSON.parse(JSON.stringify(resp));
        this.messages.push(msg);
          this.scrollToMessage();
          if (id !== msg.userId) {
             const audio = new Audio(require("@/assets/tonoMensaje.mp3"))
             audio.play();
             window.document.title = 'Continente Cargo (1)'
           }
        })
      })
    },
    openFile() {
      console.log('Coso de archivos xd')
    },
    sendMessage () {
      console.log(this.messageToSend)
      console.log(this.roomId, this.messageToSend)
      this.$hubChat.Connection.invoke('SendMessage', this.roomId, this.messageToSend).then((response) => {
        console.log(response);
        this.clearMessage()
      })
      this.getMessages(this.roomId);
    },
    clearMessage () {
      this.messageToSend.message = ''
    },
  },
}
</script>

<style scoped>
.filesChat {
  background-color: #ffffff;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.dragOverC {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
}
.inputStyle {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
}
.insideMessage {
  word-break: break-all; /* optional */
  margin-left: 5px;
  height: 100%;
  overflow: auto;
}
.messageContainer {
  display: inline-block;
  background-color: #024d98;
  border-radius: 10px;
  color: white;
  max-height: 100%;
  max-width: 70%;
}
.bottomChat {
  max-height: 10%;
  overflow-y: hidden;
  max-width: 100%;
  overflow-x: hidden;
  height: 10%;
  width: 100%;
  box-shadow: 1px -20px 27px -15px rgba(186,186,186,0.39);
  -webkit-box-shadow: 1px -20px 27px -15px rgba(186,186,186,0.39);
  -moz-box-shadow: 1px -20px 27px -15px rgba(186,186,186,0.39);
}
.middleChat {
  height: 80%;
  width: 100%;
  max-height: 80%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.topChat {
  width: 100%;
  height: 10%;
  /*border-bottom: #2c2c2c;
  border-bottom-style: solid;*/
  box-shadow: 1px 20px 27px -15px rgba(186,186,186,0.39);
  -webkit-box-shadow: 1px 20px 27px -15px rgba(186,186,186,0.39);
  -moz-box-shadow: 1px 20px 27px -15px rgba(186,186,186,0.39);
}
.mainContainer {
  height: 500px;
  width: 400px;
  max-height: 500px;
  background-color: white;
  border-radius: 5px;
}
.mainBottom {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #787878 #ffffff;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}
*::-webkit-scrollbar-track {
  background: #ffffff;
}
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
</style>
